import { faMicrosoft } from '@fortawesome/fontawesome-free-brands';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { ISyncProvider, FetchResponse } from './syncProvider';

export class OneDriveSyncProvider implements ISyncProvider {
    private static OnedriveGraphUrl = 'https://graph.microsoft.com/v1.0/me';
    private static OnedriveAuthUrl = 'https://login.live.com/oauth20_authorize.srf';
    private static OneDriveClientID = '00000000480BD845';
    private onedriveToken: string;

    public name = 'OneDrive';
    public icon = faMicrosoft as Icon;

    public disconnect() {
        this.onedriveToken = '';
        document.cookie = 'odauth=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    public loginAsync(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const root = window as any;

            const appInfo = {
                clientId: OneDriveSyncProvider.OneDriveClientID,
                scopes: 'files.readwrite.appfolder',
                redirectUri: `${location.protocol}//${location.host}/callback.html`,
                authServiceUri: OneDriveSyncProvider.OnedriveAuthUrl
            };

            root.onAuthenticated = (token: string, authWindow: Window) => {
                if (token) {
                    if (authWindow) {
                        authWindow.close();
                    }
                    this.onedriveToken = token;
                    resolve();
                }
            };

            try {
                root.provideAppInfo(appInfo);
                root.odauth();
            } catch (error) {
                reject(error);
            }
        });
    }

    public saveDataAsync(filename: string, data: string, onTokenDown: () => void): Promise<void> {
        return new Promise((resolve, reject) => {
            const sourceUrl =
                OneDriveSyncProvider.OnedriveGraphUrl + '/drive/special/approot:/' + escape(filename) + ':/content';
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', sourceUrl, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.onedriveToken);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 401 || xhr.status === 400) {
                        onTokenDown();
                    } else if (xhr.status > 400) {
                        reject(xhr.statusText);
                        return;
                    }

                    resolve();
                }
            };

            xhr.onerror = (_ev) => {
                reject(xhr.statusText);
            };

            xhr.send(data);
        });
    }

    private fetchRootAsync(): Promise<Response> {
        const dataInfoUrl = OneDriveSyncProvider.OnedriveGraphUrl + '/drive/special/approot';
        return fetch(dataInfoUrl, {
            headers: {
                Authorization: 'Bearer ' + this.onedriveToken
            }
        });
    }

    public fetchFileAsync(filename: string, onTokenDown: () => void): Promise<FetchResponse> {
        const result = new FetchResponse();
        return this.fetchRootAsync().then(() => {
            const dataInfoUrl =
                OneDriveSyncProvider.OnedriveGraphUrl + '/drive/special/approot:/' + escape(filename);
            return fetch(dataInfoUrl, {
                headers: {
                    Authorization: 'Bearer ' + this.onedriveToken
                }
            }).then((response) => {
                if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 404) {
                    if (response.status === 401 || response.status === 400) {
                        onTokenDown();
                    }
                    result.error = true;
                    return result;
                }

                return response.json().then((data: any) => {
                    result.url = data['@microsoft.graph.downloadUrl'];
                    result.dateModified = new Date(data.lastModifiedDateTime);
                    return result;
                });
            });
        });
    }

    public fetchUrl(url: string, _response: FetchResponse): Promise<string> {
        return fetch(url, {
            headers: {
                Authorization: 'Bearer ' + this.onedriveToken
            }
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error();
                }
                return response.text();
            })
            .then((text) => {
                return text;
            });
    }
}
