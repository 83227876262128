import { GlobalState, SearchData } from '../globalState';
import { Block } from './block';
import { UserStore } from './userStore';
import { BinaryReader } from '../tools/binaryReader';
import { Card } from './card';
import { CardFormat } from './cardFormat';
import { ReservedList } from './reservedList';
import { DeckGroup } from './deckGroup';
import { Deck } from './deck';
import { Set } from './set';
import { UserStoreExt } from './userStoreExt';
import { GUID } from '../tools/guid';
import { Nullable } from '../tools/nullable';
import { DownloadTools } from '../tools/downloadTools';
import { StringHelpers } from '../tools/stringHelpers';
import { DeckEntry } from './deckEntry';
import { ICard } from './ICard';
import { CardSuperType } from './cardSuperType';
import { ISyncProvider } from '../sync/syncProvider';
import { OneDriveSyncProvider } from '../sync/oneDriveSyncProvider';
import { DropBoxSyncProvider } from '../sync/dropBoxSyncProvider';
import { CardColor } from './cardColor';
import { SecureJSON } from '../tools/secureJSON';
import { ListGroup } from './listGroup';
import { List } from './list';
import { Observable } from '../tools/observable';
import { CardLanguage } from './cardLanguage';
import { CardCondition } from './cardCondition';
import { CardGrading } from './cardGrading';
import { UrzaDirectSyncProvider } from '../sync/urzaDirectSyncProvider';
import { App } from '../app';

declare const zip: any;

export class Collection {
    public static readonly EmptyGUID = '00000000-0000-0000-0000-000000000000';

    public static CardsIndex: { [key: number]: Card } = {};
    public static SetsIndex: { [key: number]: Set } = {};
    public static CardsNameIndex: { [key: string]: Array<Card> } = {};
    public static LinkedCardsIndex: { [key: number]: number } = {};
    public static Cards: Card[] = [];
    public static Sets: Set[] = [];
    public static DeckGroups: DeckGroup[] = [];
    public static Decks: Deck[] = [];
    public static ListGroups: ListGroup[] = [];
    public static Lists: List[] = [];

    private static DeckBackupCards: { [key: string]: DeckEntry[] } = {};
    private static DeckBackupSideboard: { [key: string]: DeckEntry[] } = {};
    private static DeckBackupReserve: { [key: string]: DeckEntry[] } = {};

    public static Blocks: Block[];
    public static UserStore: UserStore;
    public static UserStoreExt: UserStoreExt;
    public static UserList: string[] = [];
    public static GlobalState: GlobalState;
    public static UseFrenchForTexts: boolean;

    public static Authors: string[];
    public static MaxPrice: number = 0;
    public static MinManacost: number = 0;
    public static MaxManacost: number = 0;
    public static MinPower: number = 0;
    public static MaxPower: number = 0;
    public static MinToughness: number = 0;
    public static MaxToughness: number = 0;
    public static MinYear: number = 2900;
    public static MaxYear: number = 0;

    private static SaveTimeout?: number;
    private static SaveTimeoutExt?: number;
    private static SaveTimeoutUserSettings?: number;
    private static BootTime: Date;

    public static SyncProvider: ISyncProvider;

    public static OnSaveDoneObservable = new Observable<void>();
    public static OnUserSettingsSaveDoneObservable = new Observable<void>();

    public static ExcludedCardNames: { [key: number]: string } = {};

    public static IsLocked = false;

    private static _ExcludedCardIds?: number[];
    public static get ExcludedCardIds() {
        if (this._ExcludedCardIds === undefined) {
            if (GlobalState.DoesSettingsExist('DisabledCards')) {
                this._ExcludedCardIds = GlobalState.LoadSettings('DisabledCards', '').split(',').map(x => parseInt(x));
            } else {
                this._ExcludedCardIds = [];
            }
        }

        return this._ExcludedCardIds;
    }

    public static StoreExcludedCardIds() {
        this.GlobalState.storeSettings('DisabledCards', this.ExcludedCardIds.join(','));
    }

    public static get SortedDecks() {
        return Collection.Decks.sort((a, b) => a.Name.localeCompare(b.Name));
    }

    public static get SortedSets() {
        return Collection.Sets.sort((a, b) => a.name.localeCompare(b.name));
    }

    public static get SortedBlocks() {
        return Collection.Blocks.sort((a, b) => a.name.localeCompare(b.name));
    }

    public static GetCardsByName(name: string) {
        return Collection.Cards.filter((c) => c.nameEn.toLowerCase() === name || c.nameFr.toLowerCase() === name);
    }

    public static GetCardsByNameApprox(name: string) {
        return Collection.Cards.filter(
            (c) =>
                !c.isBackFace &&
                (StringHelpers.AlmostEquals(
                    c.nameEn.toLowerCase(),
                    name
                ) ||
                    StringHelpers.AlmostEquals(
                        c.nameFr.toLowerCase(),
                        name
                    ))
        );
    }

    public static GetCardsByNameStartingWith(name: string) {
        return Collection.Cards.filter((c) => StringHelpers.StartsWith(c.nameEn.toLowerCase(), name));
    }

    public static GetCardByName(name: string, sets: Set[], owned: boolean, cheapest: boolean, excludePromos: boolean, excludeArtSeries: boolean) {
        let cards: Card[] = [];

        if (sets.length > 0) {
            for (const set of sets) {
                if (!set) {
                    continue;
                }
                cards = set.cards.filter((c) => c.nameEn === name);
                if (cards.length > 0) {
                    break;
                }
            }
        }

        if (cards.length === 0) {
            cards = Collection.Cards.filter((c) => c.nameEn === name);
        }

        if (cards.length === 0) {
            return null;
        }

        if (cards.length === 1) {
            return cards[0];
        }

        if (excludePromos) {
            cards = cards.filter((c) => !c.isPromo);
        }

        if (excludeArtSeries) {
            cards = cards.filter((c) => !c.set.isArtSeries);
        }

        if (owned) {
            const previous = cards;
            cards = cards.filter((c) => c.count > 0);

            if (cards.length === 1) {
                return cards[0];
            }

            if (!cards.length) {
                cards = previous;
            }
        }

        if (!cards.length) {
            return null;
        }

        if (cheapest) {
            cards = cards
                .filter((c) => c.price > 0)
                .sort((a, b) => {
                    if (a.price === b.price) {
                        return 0;
                    }

                    if (a.price < b.price) {
                        return -1;
                    }

                    return 1;
                });
        }

        return cards[0];
    }

    public static GetCardByMultiverseId(id: number) {
        const cards = Collection.Cards.filter((c) => c.multiverseIdEn === id || c.multiverseIdFr === id);

        if (cards.length !== 1) {
            return null;
        }

        return cards[0];
    }

    public static GetCollection() {
        return Collection.Cards.filter((c) => c.count > 0);
    }

    public static GetBlockById(id: number) {
        return this.Blocks.filter((b) => b.id === id)[0];
    }

    public static GetBlockByName(name: string) {
        return this.Blocks.filter((b) => b.name === name)[0];
    }

    public static GetExpansionById(id: number) {
        for (const block of this.Blocks) {
            for (const expansion of block.sets) {
                if (expansion && expansion.id === id) {
                    return expansion;
                }
            }
        }

        return null;
    }

    public static GetExpansionByName(name: string) {
        name = name.toLowerCase();
        for (const block of this.Blocks) {
            for (const expansion of block.sets) {
                if (expansion.name.toLowerCase() === name) {
                    return expansion;
                }
            }
        }

        return null;
    }

    public static GetAllExpansionsByName(name: string) {
        const output = [];
        name = name.toLowerCase();
        for (const block of this.Blocks) {
            for (const expansion of block.sets) {
                if (expansion.name.toLowerCase() === name || expansion.name.toLowerCase() === name + ' collectors') {
                    output.push(expansion);
                }
            }
        }

        return output;
    }

    public static GetExpansionByCode(code: string) {
        code = code.toLowerCase();
        for (const block of this.Blocks) {
            for (const expansion of block.sets) {
                if (expansion.code.toLowerCase() === code) {
                    return expansion;
                }
            }
        }

        return null;
    }

    public static GetAllExpansionsByCode(code: string) {
        const output = [];
        code = code.toLowerCase();
        for (const block of this.Blocks) {
            for (const expansion of block.sets) {
                if (expansion.code.toLowerCase() === code) {
                    output.push(expansion);
                }
            }
        }

        return output;
    }

    private static _GetLandColor(card: Card) {
        const cardType = card.typeEn.toLowerCase();
        let cardColor = '';

        if (cardType.indexOf('swamp') !== -1) {
            cardColor += '(B)';
        }

        if (cardType.indexOf('island') !== -1) {
            cardColor += '(U)';
        }

        if (cardType.indexOf('plains') !== -1) {
            cardColor += '(W)';
        }

        if (cardType.indexOf('mountain') !== -1) {
            cardColor += '(R)';
        }

        if (cardType.indexOf('forest') !== -1) {
            cardColor += '(G)';
        }

        return cardColor;
    }

    public static Search(searchData: SearchData): Card[] {
        if (searchData.cardIds) {
            return searchData.cardIds.map((id) => Collection.CardsIndex[id]);
        }

        if (searchData.name) {
            searchData.name = StringHelpers.NoAccentVersion(searchData.name.trim().toLowerCase());
        }

        if (searchData.fulltext) {
            searchData.fulltext = searchData.fulltext.toLowerCase();
        }

        if (searchData.flavor) {
            searchData.flavor = searchData.flavor.toLowerCase();
        }

        if (searchData.comment) {
            searchData.comment = searchData.comment.toLowerCase();
        }

        if (searchData.types && ((searchData.types.length === 1 && !searchData.types[0]) || !searchData.types.length)) {
            searchData.types = undefined;
        }

        if (searchData.texts && ((searchData.texts.length === 1 && !searchData.texts[0]) || !searchData.texts.length)) {
            searchData.texts = undefined;
        }

        if (
            searchData.expansions &&
            ((searchData.expansions.length === 1 && !searchData.expansions[0]) || !searchData.expansions.length)
        ) {
            searchData.expansions = undefined;
        }

        if (
            searchData.blocks &&
            ((searchData.blocks.length === 1 && !searchData.blocks[0]) || !searchData.blocks.length)
        ) {
            searchData.blocks = undefined;
        }

        if (
            searchData.exclude &&
            ((searchData.exclude.length === 1 && !searchData.exclude[0]) || !searchData.exclude.length)
        ) {
            searchData.exclude = undefined;
        }

        let cardFormat: CardFormat;
        let excludeFormat = false;
        let manaSymbols: string[] = [];

        if (searchData.manaSymbols) {
            manaSymbols = searchData.manaSymbols.split(',').map((a) => a.trim());
        }

        if (searchData.format) {
            let formatName = searchData.format;
            if (formatName[0] === '!') {
                excludeFormat = true;
                formatName = formatName.substring(1);
            }

            cardFormat = CardFormat.GetFormatByName(formatName);
        }

        let decks: Deck[];

        if (searchData.deckIndex > 2) {
            decks = Collection.Decks.sort((a, b) => a.Name.localeCompare(b.Name));
        }

        let regexp: RegExp;
        let nameRegexp: RegExp;

        if (searchData.regex) {
            regexp = new RegExp(searchData.regex.toLowerCase(), 'm');
        }

        if (searchData.nameRegex) {
            nameRegexp = new RegExp(searchData.nameRegex.toLowerCase(), 'm');
        }

        let superTypePredicate: (card: ICard) => boolean;
        let excludeSuperTypePredicate: (card: ICard) => boolean;

        if (searchData.superTypeIndex) {
            superTypePredicate = CardSuperType.GetSuperTypeById(searchData.superTypeIndex).predicate;
        }

        if (searchData.excludedSuperTypeIndex) {
            excludeSuperTypePredicate = CardSuperType.GetSuperTypeById(searchData.excludedSuperTypeIndex).predicate;
        }

        const foundReprints: { [key: string]: boolean } = {};

        let cards = Collection.Cards.filter((card) => {
            if (searchData.hideReprints && foundReprints[card.shortNameEn]) {
                return false;
            }

            if (searchData.name && StringHelpers.NoAccentVersion(card.nameForSearch).indexOf(searchData.name) === -1) {
                return false;
            }

            if (searchData.number !== undefined && card.number !== searchData.number) {
                return false;
            }

            if (nameRegexp) {
                if (!nameRegexp.test(card.nameForSearch)) {
                    return false;
                }
            }

            if (regexp) {
                if (
                    !regexp.test(card.nameForSearch) &&
                    !regexp.test(card.typeForSearch) &&
                    !regexp.test(card.textForSearch) &&
                    !regexp.test(card.flavorForSearch)
                ) {
                    return false;
                }
            }

            if (manaSymbols && manaSymbols.length) {
                let found = false;
                for (const manaSymbol of manaSymbols) {
                    if (card.manaCost.indexOf(manaSymbol) !== -1) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    return false;
                }
            }

            if (
                searchData.fulltext &&
                card.nameForSearch.indexOf(searchData.fulltext) === -1 &&
                card.typeForSearch.indexOf(searchData.fulltext) === -1 &&
                card.textForSearch.indexOf(searchData.fulltext) === -1 &&
                card.comments.toLowerCase().indexOf(searchData.fulltext) === -1 &&
                card.flavorForSearch.indexOf(searchData.fulltext) === -1
            ) {
                return false;
            }

            if (searchData.flavor && card.flavorForSearch.indexOf(searchData.flavor) === -1) {
                return false;
            }

            if (searchData.comment && card.comments.toLowerCase().indexOf(searchData.comment) === -1) {
                return false;
            }

            if (searchData.author && StringHelpers.NoAccentVersion(card.author) !== StringHelpers.NoAccentVersion(searchData.author)) {
                return false;
            }

            if (searchData.tags && searchData.tags.length > 0) {
                if (!card.tags || card.tags.length < searchData.tags.length) {
                    return false;
                }
                for (const tag of searchData.tags) {
                    if (card.tags.indexOf(tag) === -1) {
                        return false;
                    }
                }
            }

            if (cardFormat && cardFormat.predicate) {
                if (cardFormat.predicate(card) === excludeFormat) {
                    return false;
                }
            }

            if (searchData.types) {
                if (!searchData.types.every((type) => card.typeForSearch.indexOf(type.toLowerCase().trim()) !== -1)) {
                    return false;
                }
            }

            if (searchData.texts) {
                if (!searchData.texts.every((text) => card.textForSearch.indexOf(text.toLowerCase().trim()) !== -1)) {
                    return false;
                }
            }

            if (searchData.expansions) {
                const setName = card.set.name.toLowerCase();

                if (!searchData.expansions.some((name) => setName === name.toLowerCase().trim())) {
                    return false;
                }
            }

            if (searchData.setCode) {
                const setCode = card.set.code.toLowerCase();

                if (searchData.setCode.toLowerCase() !== setCode) {
                    return false;
                }
            }

            if (searchData.blocks) {
                const blockName = card.set.block.name.toLowerCase();

                if (!searchData.blocks.some((name) => blockName === name.toLowerCase().trim())) {
                    return false;
                }
            }

            if (searchData.exclude) {
                for (const exclude of searchData.exclude) {
                    const cleaned = exclude.toLowerCase().trim();
                    if (
                        card.nameForSearch.indexOf(cleaned) !== -1 ||
                        card.typeForSearch.indexOf(cleaned) !== -1 ||
                        card.textForSearch.indexOf(cleaned) !== -1 ||
                        card.comments.toLowerCase().indexOf(cleaned) !== -1 ||
                        card.flavorForSearch.indexOf(cleaned) !== -1
                    ) {
                        return false;
                    }
                }
            }

            if (searchData.price) {
                if (card.price < searchData.price[0] || card.price > searchData.price[1]) {
                    return false;
                }
            }

            if (
                searchData.manacost &&
                !searchData.manaCostIsMaxRange &&
                (card.convertedManaCost < searchData.manacost[0] || card.convertedManaCost > searchData.manacost[1])
            ) {
                return false;
            }

            if (searchData.checkPower) {
                if (card.isCreature && card.force !== undefined && card.force !== null) {
                    if (card.force < searchData.power[0] || card.force > searchData.power[1]) {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            if (searchData.checkToughness) {
                if (card.isCreature && card.defense !== undefined && card.defense !== null) {
                    if (card.defense < searchData.toughness[0] || card.defense > searchData.toughness[1]) {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            if (searchData.checkYear) {
                if (card.set.year < searchData.year[0] || card.set.year > searchData.year[1]) {
                    return false;
                }
            }

            if (searchData.checkQuantity) {
                let cardCount = card.count;
                let foilCount = card.foilCount;
                let specialFoilCount = card.specialFoilCount;

                if (searchData.mergeReprintsForQuantity) {
                    cardCount = 0;
                    foilCount = 0;
                    specialFoilCount = 0;
                    for (const reprint of card.reprints) {
                        cardCount += reprint.count;
                        foilCount += reprint.foilCount;
                        specialFoilCount += reprint.specialFoilCount;
                    }
                }

                let countCheck = cardCount;

                if (searchData.quantityIndex === 1) {
                    countCheck = cardCount - foilCount - specialFoilCount;
                } else if (searchData.quantityIndex === 2) {
                    countCheck = foilCount;
                } else if (searchData.quantityIndex === 3) {
                    countCheck = specialFoilCount;
                }

                if (countCheck < searchData.quantity[0] || countCheck > searchData.quantity[1]) {
                    return false;
                }
            }

            if (searchData.checkQuantityToTrade) {
                const cardCount = card.toTrade;

                if (cardCount < searchData.quantityToTrade[0] || cardCount > searchData.quantityToTrade[1]) {
                    return false;
                }
            }

            if (searchData.checkWantedQuantity) {
                const cardCount = card.wanted;

                if (cardCount < searchData.wantedQuantity[0] || cardCount > searchData.wantedQuantity[1]) {
                    return false;
                }
            }

            if (searchData.collectionIndex) {
                let count = card.count;
                let foilCount = card.foilCount;
                let specialFoilCount = card.specialFoilCount;

                if (searchData.hideReprints) {
                    count = 0;
                    foilCount = 0;
                    specialFoilCount = 0;
                    card.reprints.forEach((a) => {
                        count += a.count;
                        foilCount += a.foilCount;
                        specialFoilCount += a.specialFoilCount;
                    });
                }

                if (searchData.collectionIndex === 1 && count > 0) {
                    return false;
                } else if (searchData.collectionIndex === 2 && count === 0) {
                    return false;
                } else if (searchData.collectionIndex === 3 && foilCount === 0) {
                    return false;
                } else if (searchData.collectionIndex === 4 && (count === 0 || foilCount !== 0 || specialFoilCount !== 0)) {
                    return false;
                } else if (searchData.collectionIndex === 5 && specialFoilCount === 0) {
                    return false;
                } else if (searchData.collectionIndex === 6) {
                    count = 0;
                    card.reprints.forEach((a) => {
                        count += a.count;
                    });
                    if (count > 0) {
                        return false;
                    }
                }
            }

            if (searchData.stateIndex) {
                if (searchData.stateIndex === 1 && (card.canBeStandard || card.canBeFoil || card.canBeSpecialFoil)) {
                    return false;
                } else if (searchData.stateIndex === 4 && !ReservedList.IsReserved(card)) {
                    return false;
                } else if (searchData.stateIndex === 5 && !card.isOrdered) {
                    return false;
                } else if (searchData.stateIndex === 6 && card.canBeFoil) {
                    return false;
                } else if (searchData.stateIndex === 7 && !(card.canBeFoil && card.canBeStandard)) {
                    return false;
                } else if (searchData.stateIndex === 8 && !card.canBeSpecialFoil) {
                    return false;
                } else if (searchData.stateIndex === 9 && (card.canBeFoil || card.canBeStandard)) {
                    return false;
                } else if (searchData.stateIndex === 10 && ((!(card.canBeFoil && card.canBeSpecialFoil)) || card.canBeStandard)) {
                    return false;
                }
            }

            if (searchData.proxiesIndex) {
                if (searchData.proxiesIndex === 1 && !card.isProxy) {
                    return false;
                } else if (searchData.proxiesIndex === 2 && card.isProxy) {
                    return false;
                } else if (searchData.proxiesIndex === 3 && !Collection.Decks.some(d => Deck.Some(d, (a) => a.CardID === card.id && a.isProxy))) {
                    return false;
                } else if (searchData.proxiesIndex === 4 && Collection.Decks.some(d => Deck.Some(d, (a) => a.CardID === card.id && a.isProxy))) {
                    return false;
                } else if (searchData.proxiesIndex === 5 && !card.isProxy && !Collection.Decks.some(d => Deck.Some(d, (a) => a.CardID === card.id && a.isProxy))) {
                    return false;
                } else if (searchData.proxiesIndex === 6 && (card.isProxy || Collection.Decks.some(d => Deck.Some(d, (a) => a.CardID === card.id && a.isProxy)))) {
                    return false;
                }
            }

            if (searchData.customIconIndex) {
                if (searchData.customIconIndex === 1 && card.icon) {
                    return false;
                } else if (searchData.customIconIndex - 1 !== card.icon) {
                    return false;
                }
            }

            if (searchData.miscIndex) {
                if (searchData.miscIndex === 1 && !card.isStorySpotlight) {
                    return false;
                }
            }

            if (superTypePredicate) {
                if (!superTypePredicate(card)) {
                    return false;
                }
            }

            if (excludeSuperTypePredicate) {
                if (excludeSuperTypePredicate(card)) {
                    return false;
                }
            }

            if (searchData.deckIndex) {
                if (searchData.deckIndex === 1) {
                    if (card.deckCount !== 0) {
                        return false;
                    }
                } else if (searchData.deckIndex === 2) {
                    if (card.deckCount === 0) {
                        return false;
                    }
                }  else if (searchData.deckIndex === 3) {
                    if (card.activeDeckCount === 0) {
                        return false;
                    }
                } else {
                    const deck = decks[searchData.deckIndex - 4];
                    if (!Deck.Some(deck, (entry) => entry.CardID === card.id)) {
                        return false;
                    }
                }
            }

            if (searchData.colors && searchData.colors.length) {
                if (searchData.colors.indexOf(card.colorId) === -1) {
                    return false;
                }

                if (card.colorId === 8) {
                    if (searchData.multicolors && searchData.multicolors.length) {
                        const allColors = ['B', 'R', 'U', 'W', 'G'];
                        for (const color of searchData.multicolors) {
                            if (card.manaCost.indexOf(color) === -1) {
                                return false;
                            }
                            const index = allColors.indexOf(color);

                            if (index > -1) {
                                allColors.splice(index, 1);
                            }
                        }

                        for (const color of allColors) {
                            if (card.manaCost.indexOf(color) !== -1) {
                                return false;
                            }
                        }
                    }
                }
            }

            if (searchData.colorIdentities && searchData.colorIdentities.length) {
                const cardsToTest = [card];

                if (card.otherCard) {
                    cardsToTest.push(card.otherCard);
                }

                const allColors = ['B', 'R', 'U', 'W', 'G'];
                for (const color of searchData.colorIdentities) {
                    const index = allColors.indexOf(color);

                    if (index > -1) {
                        allColors.splice(index, 1);
                    }
                }

                for (const cardToTest of cardsToTest) {
                    for (const color of allColors) {
                        if (CardColor.GetColorById(cardToTest.colorId).code === color) {
                            return false;
                        }

                        if (cardToTest.isLand && !cardToTest.textEn) {
                            const cardColor = this._GetLandColor(cardToTest);

                            if (cardColor === color) {
                                return false;
                            }
                        } else {
                            if (cardToTest.manaCost && cardToTest.manaCost.indexOf(color) !== -1) {
                                return false;
                            }

                            let cardText = cardToTest.textEn.trim();

                            if (cardText.length === 1) {
                                cardText = `(${cardText})`;
                            }

                            const totalColors = ['B', 'R', 'U', 'W', 'G'];
                            for (const cc of totalColors) {
                                for (const cc2 of totalColors) {
                                    if (cc === cc2) {
                                        continue;
                                    }
                                    cardText = cardText.replace(new RegExp(`\(${cc}${cc2}\)`, 'g'), `(${cc})(${cc2})`);
                                    cardText = cardText.replace(new RegExp(`\(${cc2}${cc}\)`, 'g'), `(${cc2})(${cc})`);
                                }
                            }

                            if (cardText.indexOf(`(${color})`) !== -1) {
                                return false;
                            }
                        }
                    }
                }
            }

            if (searchData.manaSources && searchData.manaSources.length) {
                if (card.isLand && !card.textEn) {
                    const cardColor = this._GetLandColor(card);

                    for (const source of searchData.manaSources) {
                        if (cardColor.indexOf(source) === -1) {
                            return false;
                        }
                    }

                    return true;
                }

                let cardText = card.textEn.trim();
                if (cardText.length === 1) {
                    cardText = `(${cardText})`;
                }

                if (cardText.indexOf('to your mana pool') === -1) {
                    if (!StringHelpers.StartsWith(cardText, '(') && cardText.indexOf('Add ') === -1) {
                        return false;
                    }

                    for (const source of searchData.manaSources) {
                        if (source === cardText) {
                            continue;
                        }
                        let regex = new RegExp('dd.+?\\(.*' + source + '\\).', 'gm');

                        if (!regex.test(cardText)) {
                            if (source === '(C)') {
                                regex = new RegExp('dd.+?\\(\\d+\\).', 'gm');
                                if (!regex.test(cardText)) {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        }
                    }
                } else {
                    for (const source of searchData.manaSources) {
                        let regex = new RegExp('dd.+?\\(.*' + source + '\\).+?to your mana pool', 'gm');

                        if (!regex.test(cardText)) {
                            if (source === '(C)') {
                                regex = new RegExp('dd.+?\\(\\d+\\).+?to your mana pool', 'gm');
                                if (!regex.test(cardText)) {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }

            if (searchData.languageIndex) {
                if ((!card.languages && searchData.languageIndex !== 1) || (card.languages && card.languages.indexOf(CardLanguage.CardLanguages[searchData.languageIndex - 1]) === -1)) {
                    return false;
                }
            }

            if (searchData.conditionIndex) {
                if (!card.condition || card.condition.indexOf(CardCondition.CardConditions[searchData.conditionIndex - 1].name) === -1) {
                    return false;
                }
            }

            if (searchData.addedBefore) {
                if (!card.dates || !card.dates.some(d => d <= searchData.addedBefore!)) {
                    return false;
                }
            }

            if (searchData.addedAfter) {
                if (!card.dates || !card.dates.some(d => d >= searchData.addedAfter!)) {
                    return false;
                }
            }

            if (searchData.gradingIndex) {
                if (!card.grading || card.grading.indexOf(CardGrading.CardGradings[searchData.gradingIndex - 1]) === -1) {
                    return false;
                }
            }

            if (searchData.rarities && searchData.rarities.length) {
                if (searchData.rarities.indexOf(card.rarityId) === -1) {
                    return false;
                }
            }

            if (searchData.reprintIndex === 1) {
                if (card.set.year !== card.reprints.map((c) => c.set.year).reduce((p, c) => Math.max(p, c))) {
                    return false;
                }
            }

            if (searchData.reprintIndex === 2) {
                if (card.set.name.indexOf(' Tokens') === -1) {
                    return false;
                }
            }

            if (searchData.reprintIndex === 3) {
                if (!card.isPromo) {
                    return false;
                }
            }

            if (searchData.reprintIndex === 4) {
                if (card.reprints.length > 1) {
                    return false;
                }
            }

            if (searchData.hideReprints) {
                foundReprints[card.shortNameEn] = true;
            }

            if (searchData.dfcIndex > 0) {
                if (searchData.dfcIndex === 1 && card.canTransform) {
                    return false;
                }
                if (searchData.dfcIndex === 2 && !card.canTransform) {
                    return false;
                }
            }

            return true;
        });

        if (searchData.mergeReprints) {
            const seen = new window.Set();
            cards = cards.filter((card) => {
                const k = card.nameEn + '*' + card.author;
                return seen.has(k) ? false : seen.add(k);
            });
        }

        return cards;
    }

    public static LoginAsync(): Promise<any> {
        this.BootTime = new Date();
        return new Promise((resolve, reject) => {
            const provider = GlobalState.LoadSettings('provider');
            if (!navigator.onLine || this.GlobalState.workLocally || !provider) {
                if (!provider) {
                    this.GlobalState.workLocally = true;
                }
                this.GlobalState.log('Working offline');
                this.LoadAsync(resolve, reject);
                return;
            }

            if (provider === 'Dropbox') {
                this.SyncProvider = new DropBoxSyncProvider();
            } else if (provider === 'UrzaDirect') {
                this.SyncProvider = new UrzaDirectSyncProvider();
            } else {
                this.SyncProvider = new OneDriveSyncProvider();
            }

            this.GlobalState.log('Connection to ' + this.SyncProvider.name);
            this.SyncProvider.loginAsync()
                .then(() => {
                    this.GlobalState.log('Connected to ' + this.SyncProvider.name);
                    this.GlobalState.workLocally = false;
                    this.LoadAsync(resolve, reject);
                })
                .catch((err) => {
                    reject(this.GlobalState.translate(err));
                });
        });
    }

    public static RefreshAsync(): Promise<any> {
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, new Date('1/1/1970'));
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateSettingsKey, new Date('1/1/1970'));
        this.GlobalState.onWaitRingRequired.notifyObservers(true);
        return this.CheckCloudSync()
            .then(() => {
                this.GlobalState.onWaitRingRequired.notifyObservers(false);
            })
            .catch(() => {
                this.GlobalState.onWaitRingRequired.notifyObservers(false);
            });
    }

    public static GetCells(line: string, separator = ','): string[] {
        const cells = [];

        let currentCell = '';
        let quoteCount = 0;
        for (let index = 0; index < line.length; index++) {
            const currentChar = line[index];

            if (currentChar === '"') {
                if (quoteCount === 1 && index + 1 < line.length && line[index + 1] === '"') {
                    // We keep it
                    index++;
                } else {
                    quoteCount++;
                    if (quoteCount === 2) {
                        quoteCount = 0;
                    }
                    continue;
                }
            }

            if (currentChar === separator && quoteCount === 0) {
                cells.push(currentCell);
                currentCell = '';
                continue;
            }

            currentCell += currentChar;
        }
        if (currentCell) {
            cells.push(currentCell);
        }

        return cells;
    }

    public static ImportFromCSV(data: string, merge: boolean): void {
        if (!merge) {
            for (const card of Collection.Cards) {
                card.foilCount = 0;
                card.specialFoilCount = 0;
                card.count = 0;
                card.comments = '';
                card.grading = '';
                card.languages = '';
                card.condition = '';
            }
        }

        let log = '';
        // Import
        const allTextLines = data.split(/\r\n|\n/);
        let headerLine = 0;
        let separator = ',';
        if (allTextLines[0].indexOf('"sep=') !== -1) {
            headerLine++;
            separator = allTextLines[0].substring(6, 5);
        }

        let headers = this.GetCells(allTextLines[headerLine], separator);

        headers = headers.map((h) => h.toLowerCase());

        let countIndex = headers.indexOf('count');
        if (countIndex === -1) {
            countIndex = headers.indexOf('quantity');
        }
        if (countIndex === -1) {
            countIndex = headers.indexOf('qty');
        }

        let foilCountIndex = headers.indexOf('foil count');
        if (foilCountIndex === -1) {
            foilCountIndex = headers.indexOf('foil quantity');
        }

        const specialFoilCountIndex = headers.indexOf('special foil count');

        const foilStateIndex = headers.indexOf('foil');

        let nameIndex = headers.indexOf('name');
        if (nameIndex === -1) {
            nameIndex = headers.indexOf('card');
        }
        if (nameIndex === -1) {
            nameIndex = headers.indexOf('card name');
        }

        let editionCodeIndex = headers.indexOf('edition code');
        if (editionCodeIndex === -1) {
            editionCodeIndex = headers.indexOf('set id');
        }
        if (editionCodeIndex === -1) {
            editionCodeIndex = headers.indexOf('set code');
        }
        if (editionCodeIndex === -1) {
            editionCodeIndex = headers.indexOf('printing');
        }

        let editionIndex = headers.indexOf('edition');
        if (editionIndex === -1) {
            editionIndex = headers.indexOf('set');
        }
        if (editionIndex === -1) {
            editionIndex = headers.indexOf('set name');
        }

        const urzaIdIndex = headers.indexOf('id');

        let multiverseIdIndex = headers.indexOf('multiverseid');
        if (multiverseIdIndex === -1) {
            multiverseIdIndex = headers.indexOf('multiverse id');
        }

        let cardNumber = headers.indexOf('card number');
        if (cardNumber === -1) {
            cardNumber = headers.indexOf("collector's number");
        }
        if (cardNumber === -1) {
            cardNumber = headers.indexOf('collector number');
        }

        const commentsNumber = headers.indexOf('comments');
        const conditionNumber = headers.indexOf('condition');
        const gradingNumber = headers.indexOf('grading');
        let languagesNumber = headers.indexOf('languages');

        if (languagesNumber === -1) {
            languagesNumber = headers.indexOf('language');
        }

        const maxCellIndex = Math.max(nameIndex, editionCodeIndex, editionIndex, multiverseIdIndex);

        let foundOne = false;

        for (let i = headerLine + 1; i < allTextLines.length; i++) {
            const cells = this.GetCells(allTextLines[i]);

            if (cells.length <= maxCellIndex) {
                continue;
            }

            let card: Nullable<Card> = null;

            if (urzaIdIndex > -1) {
                const urzaId = parseInt(cells[urzaIdIndex]);
                card = Collection.CardsIndex[urzaId];
            }

            if (!card && multiverseIdIndex > -1) {
                const multiverseId = parseInt(cells[multiverseIdIndex]);

                card = Collection.GetCardByMultiverseId(multiverseId);
            }

            if (!card) {
                let name = '';

                if (nameIndex > -1) {
                    name = cells[nameIndex];
                }

                if (name) {
                    let isToken = false;
                    let sets: Array<Set> = [];

                    name = name.replace('(Version ', '(v. ');

                    if (name.indexOf('Token: ') !== -1) {
                        isToken = true;
                        name = name.replace('Token: ', '').trim();
                    } else if (name.indexOf(' Token') !== -1) {
                        isToken = true;
                        name = name.replace(' Token', '').trim();
                    }

                    if (editionCodeIndex > -1) {
                        const editionCode = cells[editionCodeIndex];
                        if (editionCode) {
                            sets = Collection.GetAllExpansionsByCode(editionCode);
                        }
                    }

                    if (!sets.length && editionIndex > -1) {
                        let edition = cells[editionIndex];
                        if (edition) {
                            sets = Collection.GetAllExpansionsByName(edition + (isToken ? ' Tokens' : ''));

                            if (!sets.length) {
                                // Exceptions
                                edition = edition.replace(' Core Set', '');
                                sets = Collection.GetAllExpansionsByName(edition + (isToken ? ' Tokens' : ''));
                            }

                            if (!sets.length) {
                                sets = Collection.GetAllExpansionsByCode(edition + (isToken ? ' Tokens' : ''));
                            }
                        }
                    }

                    if (cardNumber > -1 && sets.length) {
                        const number = parseInt(cells[cardNumber]);

                        for (const set of sets) {
                            card = set.getCardByNumber(number);
                            if (card) {
                                break;
                            }
                        }
                    }

                    if (!card) {
                        card = Collection.GetCardByName(name, sets, false, false, false, false);
                    }

                }
            }

            if (card && card.otherCard && card.otherCard.count) {
                continue;
            }

            if (card === null) {
                log += allTextLines[i] + '\r\n';
                continue;
            }

            foundOne = true;

            if (foilCountIndex > -1) {
                card.foilCount += parseInt(cells[foilCountIndex]);
            }

            if (specialFoilCountIndex > -1) {
                card.specialFoilCount += parseInt(cells[specialFoilCountIndex]);
            }

            if (countIndex > -1) {
                let foilState = false;
                if (foilStateIndex > -1) {
                    const foilStateValue = cells[foilStateIndex];
                    const foilCount = parseInt(foilStateValue);

                    if (!isNaN(foilCount)) {
                        card.foilCount += foilCount;
                    } else if (foilStateValue !== 'normal') {
                        foilState = !!foilStateValue;
                    }
                }

                if (foilState) {
                    card.foilCount += parseInt(cells[countIndex]);
                    card.count += card.foilCount;
                } else {
                    card.count += parseInt(cells[countIndex]);
                }
            }

            if (commentsNumber > -1) {
                card.comments += (card.comments ? '\n' : '') + cells[commentsNumber];
            }

            if (conditionNumber > -1) {
                card.condition += (card.condition ? '\n' : '') + cells[conditionNumber];
            }

            if (languagesNumber > -1) {
                card.languages += (card.languages ? '\n' : '') + cells[languagesNumber];
            }

            if (gradingNumber > -1) {
                card.grading += (card.grading ? '\n' : '') + cells[gradingNumber];
            }
        }

        if (log && foundOne) {
            DownloadTools.DownloadAsync(log, this.GlobalState.translate('CardsNotFound'), this.GlobalState, '.txt');
        }
    }

    public static Import(data: string, merge: boolean, extension: string): void {
        if (!merge) {
            // Clean
            this.UserStore = {
                Version: App.Latest,
                Count: {},
                Foils: {},
                FoilEtcheds: {},
                Icon: {},
                Comments: {},
                Conditions: {},
                Added: {},
                Gradings: {},
                Languages: {},
                DecksGroups: [],
                ListGroups: [],
                IsOrdered: {},
                IsProxy: {},
                Tags: {}
            };

            Collection.Cards.forEach((c) => {
                c._count = 0;
                c._foilCount = 0;
                c._comments = '';
                c._isOrdered = false;
                c._isProxy = false;
                c._condition = '';
                c._grading = '';
                c._languages = '';
            });
        }

        extension = extension.substring(extension.length - 3);

        switch (extension.toLowerCase()) {
            case 'csv':
                this.ImportFromCSV(data, merge);
                break;
            case 'ugs':
            default:
                if (merge) {
                    const tempStore = SecureJSON.Parse<UserStore>(data);

                    if (tempStore) {
                        for (const key in tempStore.Count) {
                            if (this.UserStore.Count[key] !== undefined) {
                                this.UserStore.Count[key] += tempStore.Count[key];
                            } else {
                                this.UserStore.Count[key] = tempStore.Count[key];
                            }
                        }

                        for (const key in tempStore.Foils) {
                            if (this.UserStore.Foils[key] !== undefined) {
                                this.UserStore.Foils[key] += tempStore.Foils[key];
                            } else {
                                this.UserStore.Foils[key] = tempStore.Foils[key];
                            }
                        }

                        for (const key in tempStore.Comments) {
                            if (this.UserStore.Comments[key] !== undefined) {
                                this.UserStore.Comments[key] += '\n' + tempStore.Comments[key];
                            } else {
                                this.UserStore.Comments[key] = tempStore.Comments[key];
                            }
                        }

                        for (const key in tempStore.Gradings) {
                            if (this.UserStore.Gradings[key] !== undefined) {
                                this.UserStore.Gradings[key] += ',' + tempStore.Gradings[key];
                            } else {
                                this.UserStore.Gradings[key] = tempStore.Gradings[key];
                            }
                        }

                        if (tempStore.Added) {
                            for (const key in tempStore.Added) {
                                if (this.UserStore.Added[key] !== undefined) {
                                    this.UserStore.Added[key] += ',' + tempStore.Added[key];
                                } else {
                                    this.UserStore.Added[key] = tempStore.Added[key];
                                }
                            }
                        }

                        for (const key in tempStore.Languages) {
                            if (this.UserStore.Languages[key] !== undefined) {
                                this.UserStore.Languages[key] += ',' + tempStore.Languages[key];
                            } else {
                                this.UserStore.Languages[key] = tempStore.Languages[key];
                            }
                        }

                        for (const key in tempStore.Conditions) {
                            if (this.UserStore.Conditions[key] !== undefined) {
                                this.UserStore.Conditions[key] += ',' + tempStore.Conditions[key];
                            } else {
                                this.UserStore.Conditions[key] = tempStore.Conditions[key];
                            }
                        }
                    }
                } else {
                    const tempStore = SecureJSON.Parse<UserStore>(data);

                    if (tempStore) {
                        this.UserStore = tempStore;
                    }
                }
                this.ProcessUserStore();
                break;
        }

        this.SaveUserStore(new Date());
    }

    public static ExportAsync() {
        const data = JSON.stringify(this.UserStore);
        return DownloadTools.DownloadAsync(
            data,
            'collection',
            this.GlobalState,
            '.ugs',
            this.GlobalState.translate('UgsFile')
        );
    }

    private static LoadFromCache(dataKey: string): Promise<string> {
        return new Promise((resolve) => {
            const data = GlobalState.LoadSettings(dataKey, '');

            if (data) {
                resolve(data);
                return;
            }

            resolve('');
        });
    }

    public static SaveLocalUserStore(date: Date) {
        this.UserStore.DecksGroups = [];
        this.UserStore.Version = App.Latest;

        for (const gp of Collection.DeckGroups) {
            this.UserStore.DecksGroups.push({
                Name: gp.Name,
                Decks: JSON.parse(JSON.stringify(gp.Decks)) as Deck[]
            });
        }

        // Restore backup
        for (const key in this.DeckBackupCards) {
            if (!this.DeckBackupCards[key] || this.DeckBackupCards[key].length === 0) {
                continue;
            }
            for (const gp of this.UserStore.DecksGroups) {
                for (const deck of gp.Decks) {
                    if (deck.UniqueID === key) {
                        deck.Cards.push(...this.DeckBackupCards[key]);
                    }
                }
            }
        }

        for (const key in this.DeckBackupSideboard) {
            if (!this.DeckBackupSideboard[key] || this.DeckBackupSideboard[key].length === 0) {
                continue;
            }
            for (const gp of this.UserStore.DecksGroups) {
                for (const deck of gp.Decks) {
                    if (deck.UniqueID === key) {
                        deck.Sideboard.push(...this.DeckBackupSideboard[key]);
                    }
                }
            }
        }

        for (const key in this.DeckBackupReserve) {
            if (!this.DeckBackupReserve[key] || this.DeckBackupReserve[key].length === 0) {
                continue;
            }
            for (const gp of this.UserStore.DecksGroups) {
                for (const deck of gp.Decks) {
                    if (deck.UniqueID === key) {
                        deck.Reserve.push(...this.DeckBackupReserve[key]);
                    }
                }
            }
        }


        this.UserStore.ListGroups = Collection.ListGroups;
        const dataToCompress = JSON.stringify(this.UserStore);

        this.GlobalState.storeSettings('userStore' + this.GlobalState.currentUser, dataToCompress);
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
    }

    public static SaveLocalUserStoreExt(date: Date) {
        const dataToCompress = JSON.stringify(this.UserStoreExt);

        this.GlobalState.storeSettings('userStoreExt' + this.GlobalState.currentUser, dataToCompress);
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
    }

    public static SaveLocalUserList(date: Date) {
        const data = JSON.stringify(this.UserList);

        this.GlobalState.storeSettings('userList', data);
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
    }

    public static RegisterSave() {
        if (this.IsLocked || GlobalState.LoadBoolSettings('ReadOnlyMode', false)) {
            this.GlobalState.onError.notifyObservers(this.GlobalState.translate('ReadOnlyModeEngaged'));
            return;
        }

        this.GlobalState.log('Registering for sync');
        this.GlobalState.synchronizationInProgress = true;
        if (this.SaveTimeout !== undefined) {
            window.clearTimeout(this.SaveTimeout);
        }

        this.SaveTimeout = window.setTimeout(() => {
            this.SaveTimeout = undefined;
            this.SaveUserStore(new Date());
        }, 2000);
    }

    public static RegisterSaveExt() {
        if (this.SaveTimeoutExt !== undefined) {
            window.clearTimeout(this.SaveTimeoutExt);
        }

        this.SaveTimeoutExt = window.setTimeout(() => {
            this.SaveTimeoutExt = undefined;
            this.SaveUserStoreExt(new Date());
        }, 2000);
    }

    public static ForceSaveUserSettings() {
        if (this.SaveTimeoutUserSettings !== undefined) {
            window.clearTimeout(this.SaveTimeoutUserSettings);
        }

        this.SaveUserSettings();
    }

    public static RegisterSaveUserSettings() {
        if (this.SaveTimeoutUserSettings !== undefined) {
            window.clearTimeout(this.SaveTimeoutUserSettings);
        }

        this.SaveTimeoutUserSettings = window.setTimeout(() => {
            this.SaveTimeoutUserSettings = undefined;
            this.SaveUserSettings();
        }, 2000);
    }

    public static SaveUserStore(date: Date, ignoreSettings = false): void {
        this.SaveLocalUserStore(date);

        if (!navigator.onLine || this.GlobalState.workLocally) {
            this.OnSaveDoneObservable.notifyObservers();
            return;
        }

        this.GlobalState.storeBoolSettings('needSave', true);

        const data = JSON.stringify(this.UserStore);
        this.SaveDataAsync(this.GlobalState.userDataFile, data).then(() => {
            if (!ignoreSettings) {
                this.SaveUserSettings();
            }

            this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
            this.GlobalState.synchronizationInProgress = false;
            this.GlobalState.log('Sync done');

            this.GlobalState.storeBoolSettings('needSave', false);
            this.OnSaveDoneObservable.notifyObservers();
        });
    }

    public static ForcePush(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!navigator.onLine || this.GlobalState.workLocally) {
                resolve();
                return;
            }

            const date = new Date();
            this.SaveLocalUserStore(date);
            this.SaveLocalUserStoreExt(date);

            const data = JSON.stringify(this.UserStoreExt);
            this.SaveDataAsync(this.GlobalState.userDataExtFile, data)
                .then(() => {
                    const data = JSON.stringify(this.UserStore);
                    return this.SaveDataAsync(this.GlobalState.userDataFile, data).then(() => {
                        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
                        this.GlobalState.synchronizationInProgress = false;
                        this.GlobalState.log('Sync done');
                    });
                })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    public static LoadUserSettings(settings: any, globalState: GlobalState) {
        globalState.clearSettingsCache();
        let needReload = false;

        for (const key in settings) {
            if (key) {
                const currentState = localStorage.getItem(key);
                localStorage.setItem(key, settings[key]);

                needReload = needReload || currentState !== settings[key];
            }
        }

        globalState.refreshTheme(GlobalState.LoadBoolSettings('DarkMode'));
        this.GlobalState.storeDateSettings(this.GlobalState.saveDateSettingsKey, new Date());

        if (needReload) {
            location.reload();
        }
    }

    public static get ExcludedSettings() {
        const excludedKey = [
            'userStore',
            'navigationStack',
            'customDataStack',
            'userStoreExt',
            'saveDate',
            'saveDateSettings',
            'currentPageCustomData',
            'InstallPrompt',
            'needSave',
            'userList',
            'ModernExpansions',
            'StandardExpansions',
            'PioneerExpansions'
        ];

        return excludedKey;
    }

    public static SaveUserSettings() {
        if (!GlobalState.LoadBoolSettings(this.GlobalState.syncSettingsKey, false)) {
            return;
        }

        const excludedKey = this.ExcludedSettings;
        const settingsToSave: any = {};

        for (let keyIndex = 0; keyIndex < localStorage.length; keyIndex++) {
            const key = localStorage.key(keyIndex);
            if (!key || excludedKey.some(k => key.indexOf(k) !== -1)) {
                continue;
            }

            const settings = localStorage.getItem(key);
            if (settings === null) {
                continue;
            }

            settingsToSave[key] = settings;
        }

        this.SaveDataAsync(this.GlobalState.userSettingsFile, JSON.stringify(settingsToSave))
            .then(() => {
                this.GlobalState.storeDateSettings(this.GlobalState.saveDateSettingsKey, new Date());
                this.GlobalState.log('User settings saved');
                this.OnUserSettingsSaveDoneObservable.notifyObservers();
            })
            .catch((err) => {
                this.GlobalState.log('Error while saving user settings: ' + err);
            });
    }

    public static SaveUserStoreExt(date: Date): void {
        this.SaveLocalUserStore(date);
        this.SaveLocalUserStoreExt(date);

        if (!navigator.onLine || this.GlobalState.workLocally) {
            return;
        }

        const data = JSON.stringify(this.UserStoreExt);
        this.SaveDataAsync(this.GlobalState.userDataExtFile, data).then(() => {
            this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
        });
    }

    public static SaveUserList(date: Date): void {
        this.SaveLocalUserList(date);

        if (!navigator.onLine || this.GlobalState.workLocally) {
            return;
        }

        const data = JSON.stringify(this.UserList);
        this.SaveDataAsync('userlist.ugs', data).then(() => {
            this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
        });
    }

    public static SaveDataAsync(filename: string, data: string): Promise<void> {
        if (!this.SyncProvider) {
            return Promise.resolve();
        }
        return this.SyncProvider.saveDataAsync(filename, data, () => {
            this.GlobalState.onReloadRequiredError.notifyObservers(this.GlobalState.translate('TokenDown'));
        }).catch((err) => {
            this.GlobalState.onError.notifyObservers(err);
        });
    }

    private static CreateDB() {
        this.GlobalState.log('Creating empty database');
        if (!this.UserStore) {
            this.UserStore = {
                Version: App.Latest,
                Count: {},
                Foils: {},
                FoilEtcheds: {},
                Icon: {},
                Comments: {},
                Conditions: {},
                Added: {},
                Gradings: {},
                Languages: {},
                DecksGroups: [],
                ListGroups: [],
                IsOrdered: {},
                IsProxy: {},
                Tags: {}
            };
            this.SaveLocalUserStore(new Date('1/1/1970'));
        }

        if (!this.UserStoreExt) {
            this.UserStoreExt = { SavedSearches: {}, Prices: {}, FoilPrices: {} };
            this.SaveLocalUserStoreExt(new Date('1/1/1970'));
        }
    }

    private static ProcessUserStoreExt() {
        if (!this.UserStoreExt.Prices) {
            this.UserStoreExt.Prices = {};
        } else {
            for (const key in this.UserStoreExt.Prices) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }
                Collection.CardsIndex[key].price = this.UserStoreExt.Prices[key];
            }

            this.MaxPrice = 0;

            for (const card of this.Cards) {
                if (card.price > this.MaxPrice) {
                    this.MaxPrice = card.price;
                }
            }
        }

        if (!this.UserStoreExt.FoilPrices) {
            this.UserStoreExt.FoilPrices = {};
        } else {
            for (const key in this.UserStoreExt.FoilPrices) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }
                Collection.CardsIndex[key].foilPrice = this.UserStoreExt.FoilPrices[key];
            }
        }
    }

    private static CleanCardArray(cardKeys: DeckEntry[], backupStore: DeckEntry[]) {
        for (let index = 0; index < cardKeys.length; index++) {
            const card = cardKeys[index];
            const key = card.CardID;
            if (Collection.CardsIndex[key] !== undefined) {
                continue;
            }

            if (!backupStore.some(d => d.CardID === key)) {
                backupStore.push(card);
            }
            cardKeys.splice(index, 1);
            index--;
        }
    }

    private static ProcessUserStore() {
        // Check version
        if (this.UserStore.Version) {
            const fileVersion = this.UserStore.Version.split('.').map((v) => parseInt(v));
            const version = App.Latest.split('.').map((v) => parseInt(v));

            if (fileVersion[0] > version[0] || (fileVersion[0] === version[0] && fileVersion[1] > version[1]) || (fileVersion[0] === version[0] && fileVersion[1] === version[1] && fileVersion[2] > version[2])) {
                this.IsLocked = true;
                this.GlobalState.onError.notifyObservers(this.GlobalState.translate('NewerVersionDetected'));
                return;
            }
        }

        for (const key in this.UserStore.Count) {
            if (Collection.CardsIndex[key] === undefined) {
                continue;
            }

            Collection.CardsIndex[key]._count = this.UserStore.Count[key];
        }

        for (const key in this.UserStore.Foils) {
            if (Collection.CardsIndex[key] === undefined) {
                continue;
            }

            Collection.CardsIndex[key]._foilCount = this.UserStore.Foils[key];
        }

        if (!this.UserStore.FoilEtcheds) {
            this.UserStore.FoilEtcheds = {};
        } else {
            for (const key in this.UserStore.FoilEtcheds) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key]._specialFoilCount = this.UserStore.FoilEtcheds[key];
            }
        }

        if (!this.UserStore.Icon) {
            this.UserStore.Icon = {};
        } else {
            for (const key in this.UserStore.Icon) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key]._icon = this.UserStore.Icon[key];
            }
        }

        for (const card of this.Cards) {
            if (card.count === 0 || card.canBeStandard) {
                continue;
            }

            if (card.canBeFoil && card.foilCount === 0 && card.specialFoilCount === 0) {
                card._foilCount = card.count;
            }
        }

        for (const key in this.UserStore.Comments) {
            if (Collection.CardsIndex[key] === undefined) {
                continue;
            }

            Collection.CardsIndex[key]._comments = this.UserStore.Comments[key];
        }

        for (const key in this.UserStore.Tags) {
            if (Collection.CardsIndex[key] === undefined || !this.UserStore.Tags[key]) {
                continue;
            }

            Collection.CardsIndex[key]._tags = this.UserStore.Tags[key].split(',');
        }

        if (!this.UserStore.Conditions) {
            this.UserStore.Conditions = {};
        } else {
            for (const key in this.UserStore.Conditions) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                const condition = this.UserStore.Conditions[key];
                const card = Collection.CardsIndex[key];

                if (isNaN(condition)) {
                    card._condition = CardCondition.Deserialize(condition);
                } else {
                    card._condition = CardCondition.Decode(condition, card.count);
                }
            }
        }

        if (!this.UserStore.Languages) {
            this.UserStore.Languages = {};
        } else {
            for (const key in this.UserStore.Languages) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                const language = this.UserStore.Languages[key];
                const card = Collection.CardsIndex[key];

                if (isNaN(language)) {
                    card._languages = CardLanguage.Deserialize(language);
                } else {
                    card._languages = CardLanguage.Decode(language, card.count);
                }
            }
        }

        if (!this.UserStore.Gradings) {
            this.UserStore.Gradings = {};
        } else {
            for (const key in this.UserStore.Gradings) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                const grading = this.UserStore.Gradings[key];
                const card = Collection.CardsIndex[key];

                if (isNaN(grading)) {
                    card._grading = CardGrading.Deserialize(grading);
                } else {
                    card._grading = CardGrading.Decode(grading, card.count);
                }
            }
        }

        if (!this.UserStore.Added) {
            this.UserStore.Added = {};
        } else {
            for (const key in this.UserStore.Added) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                const dates = this.UserStore.Added[key];
                const card = Collection.CardsIndex[key];

                card._dates = dates.split(',').map((d: string) => new Date(d));
            }
        }

        if (this.UserStore.IsOrdered) {
            for (const key in this.UserStore.IsOrdered) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key]._isOrdered = this.UserStore.IsOrdered[key];
            }
        } else {
            this.UserStore.IsOrdered = {};
        }

        if (this.UserStore.IsProxy) {
            for (const key in this.UserStore.IsProxy) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key]._isProxy = this.UserStore.IsProxy[key];
            }
        } else {
            this.UserStore.IsProxy = {};
        }

        if (this.UserStore.ToTrade) {
            for (const key in this.UserStore.ToTrade) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key].toTrade = this.UserStore.ToTrade[key];
            }
        }

        if (this.UserStore.Wanted) {
            for (const key in this.UserStore.Wanted) {
                if (Collection.CardsIndex[key] === undefined) {
                    continue;
                }

                Collection.CardsIndex[key].wanted = this.UserStore.Wanted[key];
            }
        }

        if (this.UserStore.SetComments) {
            for (const key in this.UserStore.SetComments) {
                if (Collection.SetsIndex[key] === undefined) {
                    continue;
                }
                Collection.SetsIndex[key].comments = this.UserStore.SetComments[key];
            }
        }

        if (this.UserStore.CustomBlocks) {
            const currentCustomBlocks = Collection.Blocks.filter((block) => block.isCustom);

            for (const currentCustomBlock of currentCustomBlocks) {
                const index = Collection.Blocks.indexOf(currentCustomBlock);

                if (index === -1) {
                    continue;
                }

                Collection.Blocks.splice(index, 1);
            }

            for (const customBlock of this.UserStore.CustomBlocks) {
                const newBlock = new Block();
                newBlock.name = customBlock.name;
                newBlock.id = customBlock.id;
                newBlock.index = customBlock.index;
                newBlock.isCustom = true;

                newBlock.sets = Collection.Sets.filter((set) => customBlock.sets.indexOf(set.id) !== -1);

                Collection.Blocks.push(newBlock);
            }
        }

        if (this.UserStore.DecksGroups) {
            Collection.DeckGroups = this.UserStore.DecksGroups;
            Collection.Decks = [];

            let needToSave = false;
            this.UserStore.DecksGroups.forEach((dg) => {
                for (const deck of dg.Decks) {
                    if (!deck.UniqueID) {
                        deck.UniqueID = GUID.Generate();
                        needToSave = true;
                    }
                    if (!deck.Reserve) {
                        deck.Reserve = [];
                    }

                    if (!Collection.DeckBackupCards[deck.UniqueID]) {
                        Collection.DeckBackupCards[deck.UniqueID] = [];
                    }

                    if (!Collection.DeckBackupSideboard[deck.UniqueID]) {
                        Collection.DeckBackupSideboard[deck.UniqueID] = [];
                    }

                    if (!Collection.DeckBackupReserve[deck.UniqueID]) {
                        Collection.DeckBackupReserve[deck.UniqueID] = [];
                    }

                    this.CleanCardArray(deck.Cards, Collection.DeckBackupCards[deck.UniqueID]);
                    this.CleanCardArray(deck.Sideboard, Collection.DeckBackupSideboard[deck.UniqueID]);
                    this.CleanCardArray(deck.Reserve, Collection.DeckBackupReserve[deck.UniqueID]);

                    Collection.Decks.push(deck);
                }
            });

            if (needToSave) {
                this.SaveUserStore(new Date());
            }
        } else {
            Collection.DeckGroups = [];
            Collection.Decks = [];
        }

        if (this.UserStore.ListGroups) {
            Collection.ListGroups = this.UserStore.ListGroups;
            Collection.Lists = [];

            let needToSave = false;
            this.UserStore.ListGroups.forEach((lg) => {
                for (const list of lg.Lists) {
                    if (!list.UniqueID) {
                        list.UniqueID = GUID.Generate();
                        needToSave = true;
                    }

                    Collection.Lists.push(list);
                }
            });

            if (needToSave) {
                this.SaveUserStore(new Date());
            }
        } else {
            Collection.ListGroups = [];
            Collection.Lists = [];
        }
    }

    private static LoadFileAsync(
        filename: string,
        doNotAsk = false,
        saveDateKey?: string
    ): Promise<{ data?: string; ignore: boolean; notFound?: boolean }> {

        if (!saveDateKey) {
            saveDateKey = this.GlobalState.saveDateKey;
        }

        this.GlobalState.log('Checking sync state for ' + filename);

        return this.SyncProvider.fetchFileAsync(filename, () => {
            this.GlobalState.onReloadRequiredError.notifyObservers(this.GlobalState.translate('TokenDown'));
        }).then((response) => {
                if (response.error) {
                    return Promise.resolve({ ignore: false, notFound: true });
                }

                // Check times
                const lastServerModifiedDateTime = response.dateModified;
                const lastSaveDate = GlobalState.LoadDateSettings(saveDateKey!, new Date('1/1/1970'));

                this.GlobalState.log('Server timestamp:', lastServerModifiedDateTime);
                this.GlobalState.log('Local timestamp:', lastSaveDate);

                if (
                    lastSaveDate > lastServerModifiedDateTime ||
                    lastServerModifiedDateTime.getTime() - lastSaveDate.getTime() < 10000
                ) {
                    this.GlobalState.log('Local version is newer');
                    return Promise.resolve({ ignore: true });
                }

                if (doNotAsk) {
                    this.GlobalState.log('Loading server version for ' + filename);
                    return this.SyncProvider.fetchUrl(response.url, response).then((text) => {
                        return {
                            data: text,
                            ignore: false
                        };
                    });
                }

                return this.GlobalState.showConfirmDialog(
                    this.GlobalState.translate('LoadServerData').replace('${0}', this.SyncProvider.name)
                ).then((value) => {
                    if (!value) {
                        this.GlobalState.storeDateSettings(saveDateKey!, new Date());
                        return Promise.resolve({ ignore: true });
                    }
                    this.GlobalState.log('Loading server version for ' + filename);
                    return this.SyncProvider.fetchUrl(response.url, response).then((text) => {
                        return {
                            data: text,
                            ignore: false
                        };
                    });
                });
            })
            .catch(() => {
                return Promise.resolve({ ignore: true });
            });
    }

    private static ProcessSettings(data :{ data?: string; ignore: boolean; notFound?: boolean }) {
        if (
            data.ignore ||
            (GlobalState.DoesSettingsExist(this.GlobalState.syncSettingsKey) &&
                !GlobalState.LoadBoolSettings(this.GlobalState.syncSettingsKey))
        ) {
            return;
        }
        if (data.notFound) {
            return;
        }
        if (data.data) {
            const parsedData = SecureJSON.Parse<any>(data.data);

            if (parsedData) {
                this.LoadUserSettings(parsedData, this.GlobalState);
            }
        }
    }

    private static async CheckCloudSync(): Promise<void> {
        if (!navigator.onLine || this.GlobalState.workLocally) {
            this.GlobalState.log('Working offline');
            return Promise.resolve();
        }

        if (GlobalState.LoadBoolSettings('needSave', false)) {
            const data = JSON.stringify(this.UserStoreExt);
            return this.SaveDataAsync(this.GlobalState.userDataExtFile, data)
                .then(() => {
                    const data = JSON.stringify(this.UserStore);
                    return this.SaveDataAsync(this.GlobalState.userDataFile, data).then(() => {
                        const date = new Date();
                        this.GlobalState.storeDateSettings(this.GlobalState.saveDateKey, date);
                        this.GlobalState.synchronizationInProgress = false;
                        this.GlobalState.log('Sync done after restoring from token down');
                    });
                })
                .then(() => {
                    this.GlobalState.storeBoolSettings('needSave', false);
                    return this.CheckCloudSync();
                });
        }
        let ignoreMessage = true;

        const saveActionList: (() => void)[] = [];

        // User list
        let state = await this.LoadFileAsync('userlist.ugs', true);
        if (!state.ignore) {
            if (state.notFound) {
                saveActionList.push(() => this.SaveUserList(new Date()));
            } else  if (state.data) {
                const parsedData = SecureJSON.Parse<string[]>(state.data);

                if (parsedData) {
                    this.UserList = parsedData;
                    if (!Array.isArray(this.UserList)) {
                        this.UserList = [];
                    }
                    saveActionList.push(() => this.SaveUserList(new Date()));
                }
            }
        }

        // User data file
        state = await this.LoadFileAsync(this.GlobalState.userDataFile);
        if (state.notFound) {
            saveActionList.push(() => this.SaveUserStore(new Date()));
        } else if (!state.ignore && state.data) {
            ignoreMessage = false;
            const tempStore = SecureJSON.Parse<UserStore>(state.data);

            if (tempStore && tempStore?.Count) {
                this.UserStore = tempStore;
            }

            this.ProcessUserStore();
            saveActionList.push(() => this.SaveUserStore(new Date(), true));
        }

        // Settings
        this.ProcessSettings(await this.LoadFileAsync(this.GlobalState.userSettingsFile, true, this.GlobalState.saveDateSettingsKey));

        state = await this.LoadFileAsync(this.GlobalState.userDataExtFile, true);
        if (!state.ignore) {
            if (state.notFound) {
                saveActionList.push(() => this.SaveUserStoreExt(new Date()));
            } else if (state.data) {
                const parsedData = SecureJSON.Parse<any>(state.data);

                if (parsedData && parsedData?.Prices) {
                    this.UserStoreExt = parsedData;
                    this.ProcessUserStoreExt();
                    saveActionList.push(() => this.SaveUserStoreExt(new Date()));
                }
            }
        }

        // Saving
        for (const saveAction of saveActionList) {
            saveAction();
        }

        if (ignoreMessage) {
            return;
        }

        this.GlobalState.log('Importation from ' + this.SyncProvider.name + ' successful');
        this.GlobalState.onForcePageRefresh.notifyObservers();
        this.GlobalState.onMessage.notifyObservers(this.GlobalState.translate('DataLoadedFromSync'));
        this.GlobalState.onNotificationRequired.notifyObservers(
            this.GlobalState.translate('DataLoadedFromSync')
        );
    }

    private static Decode(data: ArrayBuffer) {
        const reader = new BinaryReader(data);

        this.GlobalState.log('Decoding database');
        const decodingStartTime = new Date();
        const blocksCount = reader.readInt32();
        this.Blocks = [];
        for (let index = 0; index < blocksCount; index++) {
            const block = Block.Deserialize(reader);

            if (!block.ignore) {
                this.Blocks.push(block);
            }
        }

        let now = new Date();
        this.GlobalState.log('Decoding duration: ' + (now.getTime() - decodingStartTime.getTime()) + 'ms');

        this.GlobalState.log('Preparing lists');
        const preparingListStartTime = new Date();
        // Authors and card limits
        this.Authors = [];
        const authorsDictionary: { [key: string]: boolean } = {};

        for (const card of this.Cards) {
            if (!authorsDictionary[card.author]) {
                this.Authors.push(card.author);
                authorsDictionary[card.author] = true;
            }

            if (card.force) {
                if (card.force > this.MaxPower) {
                    this.MaxPower = card.force;
                }

                if (card.force < this.MinPower) {
                    this.MinPower = card.force;
                }
            }

            if (card.defense) {
                if (card.defense > this.MaxToughness) {
                    this.MaxToughness = card.defense;
                }

                if (card.defense < this.MinToughness) {
                    this.MinToughness = card.defense;
                }
            }

            if (card.price > this.MaxPrice) {
                this.MaxPrice = card.price;
            }

            if (card.convertedManaCost > this.MaxManacost && card.convertedManaCost < 1000) {
                this.MaxManacost = card.convertedManaCost;
            }

            if (card.convertedManaCost < this.MinManacost) {
                this.MinManacost = card.convertedManaCost;
            }

            if (card.set.year) {
                if (card.set.year > this.MaxYear) {
                    this.MaxYear = card.set.year;
                }

                if (card.set.year < this.MinYear) {
                    this.MinYear = card.set.year;
                }
            }

            if (!card.nameFr) {
                const reprints = card.reprints;

                for (let index = 0; index < reprints.length; index++) {
                    const reprint = reprints[index];
                    if (reprint.nameFr) {
                        card.nameFr = reprint.nameFr;
                        card.textFr = reprint.textFr;
                        card.typeFr = reprint.typeFr;
                        card.flavorFr = reprint.flavorFr;
                        break;
                    }
                }
            }
        }

        this.Authors.sort((a, b) => a.localeCompare(b));

        now = new Date();
        this.GlobalState.log('Preparing lists duration: ' + (now.getTime() - preparingListStartTime.getTime()) + 'ms');
    }

    private static LoadFullAsync() {
        const loadingStartingTime = new Date();
        return fetch('./db.data').then((response) => {
            return response.arrayBuffer().then((data) => {
                const now = new Date();
                this.GlobalState.log('Loading duration: ' + (now.getTime() - loadingStartingTime.getTime()) + 'ms');

                this.Decode(data);
            });
        });
    }

    private static LoadZipAsync() {
        const loadingStartingTime = new Date();
        return fetch('./db.zip').then((response) => {
            return response.blob().then((blob) => {
                const now = new Date();
                this.GlobalState.log('Loading duration: ' + (now.getTime() - loadingStartingTime.getTime()) + 'ms');
                this.GlobalState.log('Uncompressing database');
                const uncompressingStartingTime = new Date();
                return new Promise<void>((zipResolve) => {
                    // use a BlobReader to read the zip from a Blob object
                    zip.createReader(
                        new zip.BlobReader(blob),
                        (zipReader: any) => {
                            // get all entries from the zip
                            zipReader.getEntries((entries: any) => {
                                if (entries.length) {
                                    // get first entry content as text
                                    entries[0].getData(
                                        new zip.BlobWriter(),
                                        (blobData: Blob) => {
                                            new Response(blobData).arrayBuffer().then((data) => {
                                                const now = new Date();
                                                this.GlobalState.log(
                                                    'Uncompressing duration: ' +
                                                        (now.getTime() - uncompressingStartingTime.getTime()) +
                                                        'ms'
                                                );
                                                this.Decode(data);
                                                // close the zip reader
                                                zipReader.close(() => {
                                                    zipResolve();
                                                });
                                            });
                                        },
                                        () => {
                                            // cb
                                        }
                                    );
                                }
                            });
                        },
                        () => {
                            // onerror callback
                        }
                    );
                });
            });
        });
    }

    private static LoadAsync<T>(resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) {
        Collection.CardsIndex = {};
        Collection.CardsNameIndex = {};
        Collection.Cards = [];
        Collection.DeckGroups = [];
        Collection.Decks = [];
        Collection.Sets = [];

        this.GlobalState.log('Loading database');

        let loadingPromise: Promise<unknown>;

        if ('serviceWorker' in navigator) {
            loadingPromise = this.LoadFullAsync();
        } else {
            loadingPromise = this.LoadZipAsync();
        }

        loadingPromise
            .then(() => {
                this.GlobalState.log('Loading userdata.ugs');
                return this.LoadFromCache('userStore' + this.GlobalState.currentUser);
            })
            .then((data) => {
                if (data) {
                    const parsedData = SecureJSON.Parse<UserStore>(data);
                    if (parsedData) {
                        this.UserStore = parsedData;
                        this.ProcessUserStore();
                    }
                } else {
                    this.CreateDB();
                }
            })
            .then(() => {
                this.GlobalState.log('Loading userdata-ext.ugs');
                return this.LoadFromCache('userStoreExt' + this.GlobalState.currentUser);
            })
            .then((data) => {
                if (data) {
                    const parsedData = SecureJSON.Parse<UserStoreExt>(data);
                    if (parsedData) {
                        this.UserStoreExt = parsedData;
                        this.ProcessUserStoreExt();
                    }
                } else {
                    this.CreateDB();
                }
                return Promise.resolve();
            })
            .then(() => {
                this.GlobalState.log('Loading user list');
                return this.LoadFromCache('userList');
            })
            .then((data) => {
                if (data) {
                    const parsedData = SecureJSON.Parse<string[]>(data);
                    if (parsedData) {
                        this.UserList = parsedData;
                        if (!Array.isArray(this.UserList)) {
                            this.UserList = [];
                        }
                    }
                } else {
                    this.UserList = [];
                }
                return Promise.resolve();
            })
            .then(() => {
                const now = new Date();
                this.GlobalState.log('All green..starting');
                this.GlobalState.log('Boot duration: ' + (now.getTime() - this.BootTime.getTime()) + 'ms');
                setTimeout(() => {
                    this.CheckCloudSync();
                });
                resolve();
            })
            .catch(() => {
                reject();
            });
    }
}
