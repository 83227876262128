import * as React from 'react';
import { GlobalState } from '../globalState';

require('../scss/helpPage.scss');

interface IHelpPageProps {
    globalState: GlobalState;
}

export class HelpPage extends React.Component<IHelpPageProps> {
    constructor(props: IHelpPageProps) {
        super(props);
    }

    componentWillUnmount() {
        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidMount() {
        // nothing to do
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const features = [
            {
                label: translate('DeckAsGroupList'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/9-8-0'
            },
            {
                label: translate('Proxies').replace(':', ''),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/10-3-0'
            },
            {
                label: translate('SettingCustomIcon'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/10-5-0'
            },
            {
                label: translate('HidingCards'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/10-4-0'
            },
            {
                label: translate('QuicklyAddingCardsToDeck'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/9-0-0'
            },
            {
                label: translate('HowListsWork'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/how-the-lists-work'
            }
        ];

        const troubles = [
            {
                label: translate('VersionUpdate'),
                link: 'https://www.deltakosh.com/urzagatherer/announcements/why-is-my-app-website-does-not-update-to-latest-version'
            },
            {
                label: translate('WhyPricesAreIncorrect'),
                link: 'https://www.deltakosh.com/urzagatherer/questions-2/help-make-urzagatherer-better'
            }
        ];

        return (
            <div className="page">
                <div className="help-page">
                    <div className="intro">
                        <div className="help-logo">
                            <img id="help-img" className="help-img" src="/images/splashscreen.png" />
                        </div>
                        <div className="help-description">
                            {translate('Help')}
                        </div>
                    </div>
                    <div className="help-section">
                            {translate('Troubleshooting')}
                        </div>
                    <div className="help-docs">
                        {
                            troubles.map(trouble => {
                                return (
                                    <div className='help-doc-item' key={trouble.link}>
                                        <a target="_blank" href={trouble.link} rel="noreferrer">{trouble.label}</a>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <br></br>
                    <div className="help-section">
                            {translate('Features')}
                        </div>
                    <div className="help-docs">
                        {
                            features.map(feature => {
                                return (
                                    <div className='help-doc-item' key={feature.link}>
                                        <a target="_blank" href={feature.link} rel="noreferrer">{feature.label}</a>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
