import { ICard } from './ICard';
import { GlobalState } from '../globalState';

export class CardFormat {
    public static CardFormats: CardFormat[] = [];
    public static BrawlIndex = 2;
    public static CommanderIndex = 3;
    public static DuelCommanderIndex = 11;
    public static StandardFormat: CardFormat;

    private static _AllFormatsBanned = [
        'Cleanse',
        'Crusade',
        'Imprison',
        'Invoke Prejudice',
        'Jihad',
        'Pradesh Gypsies',
        'Stone Throwing Devils'
    ];

    private static _StandardExpansions = [
        'Streets of New Capenna',
        'Kamigawa: Neon Dynasty',
        'Innistrad: Crimson Vow',
        'Innistrad: Midnight Hunt',
        'Dominaria United',
        "The Brothers' War",
        'Phyrexia: All Will Be One',
        'March of the Machine',
        'March of the Machine: The Aftermath',
        'Wilds of Eldraine',
        'The Lost Caverns of Ixalan',
        'Murders at Karlov Manor',
        'Outlaws of Thunder Junction',
        'The Big Score',
        'Breaking News'
    ];

    private static _ModernExpansions = [
        'Eighth Edition',
        'Mirrodin',
        'Darksteel',
        'Fifth Dawn',
        'Champions of Kamigawa',
        'Betrayers of Kamigawa',
        'Saviors of Kamigawa',
        'Ninth Edition',
        'Ravnica: City of Guilds',
        'Guildpact',
        'Dissension',
        'Coldsnap',
        'Time Spiral',
        'Time Spiral - Timeshifted',
        'Planar Chaos',
        'Future Sight',
        'Tenth Edition',
        'Lorwyn',
        'Morningtide',
        'Shadowmoor',
        'Eventide',
        'Shards of Alara',
        'Conflux',
        'Alara Reborn',
        'Magic 2010',
        'Zendikar',
        'Worldwake',
        'Rise of the Eldrazi',
        'Magic 2011',
        'Scars of Mirrodin',
        'Mirrodin Besieged',
        'New Phyrexia',
        'Magic 2012',
        'Innistrad',
        'Dark Ascension',
        'Avacyn Restored',
        'Magic 2013',
        'Return to Ravnica',
        'Gatecrash',
        "Dragon's Maze",
        'Magic 2014',
        'Theros',
        'Born of the Gods',
        'Journey into Nyx',
        'Magic 2015',
        'Fate Reforged',
        'Khans of Tarkir',
        'Dragons of Tarkir',
        'Magic Origins',
        'Battle for Zendikar',
        'Oath of the Gatewatch',
        'Shadows over Innistrad',
        'Eldritch Moon',
        'Kaladesh',
        'Aether Revolt',
        'Amonkhet',
        'Hour of Devastation',
        'Ixalan',
        'Rivals of Ixalan',
        'Dominaria',
        'Core Set 2019',
        'Core Set 2020',
        'War of the Spark',
        'Ravnica Allegiance',
        'Guilds of Ravnica',
        'Modern Horizons',
        'Modern Horizons 2',
        'Throne of Eldraine',
        'Theros: Beyond Death',
        'Ikoria: Lair of Behemoths',
        'Core Set 2021',
        'Zendikar Rising',
        'Kaldheim',
        'Strixhaven: School of Mages',
        'Adventures in the Forgotten Realms',
        'Innistrad: Midnight Hunt',
        'Innistrad: Crimson Vow',
        'Kamigawa: Neon Dynasty',
        'Streets of New Capenna',
        'Dominaria United',
        "The Brothers' War",
        'Phyrexia: All Will Be One',
        'March of the Machine',
        'March of the Machine: The Aftermath',
        'The Lord of the Rings: Tales of Middle-earth',
        'Wilds of Eldraine',
        'The Lost Caverns of Ixalan',
        'Murders at Karlov Manor',
        'Outlaws of Thunder Junction',
        'The Big Score',
        'Breaking News'
    ];

    private static _PioneerExpansions = [
        'Return to Ravnica',
        'Gatecrash',
        "Dragon's Maze",
        'Magic 2014',
        'Theros',
        'Born of the Gods',
        'Journey into Nyx',
        'Magic 2015',
        'Fate Reforged',
        'Khans of Tarkir',
        'Dragons of Tarkir',
        'Magic Origins',
        'Battle for Zendikar',
        'Oath of the Gatewatch',
        'Shadows over Innistrad',
        'Eldritch Moon',
        'Kaladesh',
        'Aether Revolt',
        'Amonkhet',
        'Hour of Devastation',
        'Ixalan',
        'Rivals of Ixalan',
        'Dominaria',
        'Core Set 2019',
        'Core Set 2020',
        'War of the Spark',
        'Ravnica Allegiance',
        'Guilds of Ravnica',
        'Throne of Eldraine',
        'Theros: Beyond Death',
        'Ikoria: Lair of Behemoths',
        'Core Set 2021',
        'Zendikar Rising',
        'Kaldheim',
        'Strixhaven: School of Mages',
        'Adventures in the Forgotten Realms',
        'Innistrad: Midnight Hunt',
        'Innistrad: Crimson Vow',
        'Kamigawa: Neon Dynasty',
        'Streets of New Capenna',
        'Dominaria United',
        "The Brothers' War",
        'Phyrexia: All Will Be One',
        'March of the Machine',
        'March of the Machine: The Aftermath',
        'Wilds of Eldraine',
        'The Lost Caverns of Ixalan',
        'Murders at Karlov Manor',
        'Outlaws of Thunder Junction',
        'The Big Score',
        'Breaking News'
    ];

    public static async GetUpdatedFormatsAsync(gs: GlobalState) {
        try {
            const response = await fetch('https://urzabackend.azurewebsites.net/formats');
            const list = await response.json();

            this._StandardExpansions = list.standard;
            this._ModernExpansions = list.modern;
            this._PioneerExpansions = list.pioneer;

            gs.storeSettings('StandardExpansions', this._StandardExpansions.join(','));
            gs.storeSettings('ModernExpansions', this._ModernExpansions.join(','));
            gs.storeSettings('PioneerExpansions', this._PioneerExpansions.join(','));

            gs.log('Card formats loaded');
        } catch (e) {
            // Just ignore

            if (GlobalState.DoesSettingsExist('StandardExpansions')) {
                this._StandardExpansions = GlobalState.LoadSettings('StandardExpansions').split(',');
            }
            if (GlobalState.DoesSettingsExist('ModernExpansions')) {
                this._ModernExpansions = GlobalState.LoadSettings('ModernExpansions').split(',');
            }
            if (GlobalState.DoesSettingsExist('PioneerExpansions')) {
                this._PioneerExpansions = GlobalState.LoadSettings('PioneerExpansions').split(',');
            }

            gs.log('Card formats loaded from cache');
        }
    }

    public constructor(public name: string, public index: number, public predicate?: (card: ICard) => boolean) {}

    public static GetFormatByName(name: string): CardFormat {
        return this.CardFormats.filter((format) => format.name === name)[0];
    }

    public static GetFormatByIndex(index: number): CardFormat {
        return this.CardFormats.filter((format) => format.index === index)[0];
    }

    public static CreateList(gs: GlobalState) {
        const translate = gs.translate.bind(gs);

        CardFormat.CardFormats.push(new CardFormat(translate('AllFormats'), 0));

        CardFormat.CardFormats.push(
            new CardFormat(translate('Pauper'), 6, (c) => {
                // 3/7/2022
                const banned = [
                    'Atog',
                    "Arcum's Astrolabe",
                    'Chatterstorm',
                    'Cloud of Faeries',
                    'Cloudpost',
                    'Cranial Plating',
                    'Daze',
                    'Disciple of the Vault',
                    'Empty the Warrens',
                    'Fall from Favor',
                    'Frantic Search',
                    'Galvanic Relay',
                    'Gitaxian Probe',
                    'Grapeshot',
                    'Gush',
                    'Invigorate',
                    'Monastery Swiftspear',
                    'Mystic Sanctuary',
                    'Peregrine Drake',
                    'Prophetic Prism',
                    "Sojourner's Companion",
                    'Temporal Fissure',
                    'Treasure Cruise',
                    'High Tide',
                    'Hymn to Tourach',
                    'Sinkhole'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                const forced = ['Carrion Feeder', "Chainer's Edict", 'Fireball', 'Thermokarst', 'Claws of Wirewood', 'Circular Logic', 'Bottle Gnomes'];

                if (forced.indexOf(c.shortNameEn) !== -1) {
                    return true;
                }

                const candidates = c.reprints.filter(c => c.set.name.indexOf('Art Series') === -1 && !c.isToken);

                return candidates && candidates.length > 0 && candidates.some(c => c.rarityId === 1);
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Modern'), 5, (c) => {
                // 3/7/2022
                const banned = [
                    'Ancient Den',
                    "Arcum's Astrolabe",
                    'Birthing Pod',
                    'Blazing Shoal',
                    'Bridge from Below',
                    'Chrome Mox',
                    'Cloudpost',
                    'Dark Depths',
                    'Deathrite Shaman',
                    'Dig Through Time',
                    'Dread Return',
                    'Eye of Ugin',
                    'Faithless Looting',
                    'Gitaxian Probe',
                    'Golgari Grave-Troll',
                    'Glimpse of Nature',
                    'Great Furnace',
                    "Green Sun's Zenith",
                    'Hogaak, Arisen Necropolis',
                    'Hypergenesis',
                    'Krark-Clan Ironworks',
                    'Lurrus of the Dream-Den',
                    'Nadu, Winged Wisdom',
                    'Mental Misstep',
                    'Mox Opal',
                    'Mycosynth Lattice',
                    'Oko, Thief of Crowns',
                    'Once Upon a Time',
                    'Ponder',
                    'Punishing Fire',
                    'Rite of Flame',
                    'Seat of the Synod',
                    'Second Sunrise',
                    'Seething Song',
                    "Sensei's Divining Top",
                    'Splinter Twin',
                    'Skullclamp',
                    'Summer Bloom',
                    'Treasure Cruise',
                    'Tree of Tales',
                    "Umezawa's Jitte",
                    'Vault of Whispers',
                    'Yorion, Sky Nomad'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                return c.reprints.some((card) => this._ModernExpansions.indexOf(card.set.sourceName) !== -1 || this._ModernExpansions.indexOf(card.set.sourceName.replace(' Collectors', '')) !== -1);
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Pioneer'), 7, (c) => {
                // 3/7/2022
                const banned = [
                    'Balustrade Spy',
                    'Bloodstained Mire',
                    'Felidar Guardian',
                    'Field of the Dead',
                    'Flooded Strand',
                    'Inverter of Truth',
                    'Kethis, the Hidden Hand',
                    'Leyline of Abundance',
                    'Lurrus of the Dream-Den',
                    'Nexus of Fate',
                    'Once Upon a Time',
                    'Oko, Thief of Crowns',
                    'Polluted Delta',
                    "Smuggler's Copter",
                    'Teferi, Time Raveler',
                    'Undercity Informer',
                    'Underworld Breach',
                    "Uro, Titan of Nature's Wrath",
                    'Veil of Summer',
                    'Walking Ballista',
                    'Wilderness Reclamation',
                    'Windswept Heath',
                    'Wooded Foothills'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                return c.reprints.some((card) => this._PioneerExpansions.indexOf(card.set.sourceName) !== -1);
            })
        );

        this.StandardFormat = new CardFormat(translate('Standard'), 8, (c) => {
            // 3/16/2023
            const banned = [
                'The Meathook Massacre',
                'Fable of the Mirror-Breaker',
                'Invoke Despair',
                'Reckoner Bankbuster',
                'Path to Exile'
            ];

            banned.push(...this._AllFormatsBanned);

            if (banned.indexOf(c.shortNameEn) !== -1) {
                return false;
            }

            return c.reprints.some((card) => CardFormat._StandardExpansions.indexOf(card.set.sourceName) !== -1);
        });

        CardFormat.CardFormats.push(this.StandardFormat);

        CardFormat.CardFormats.push(
            new CardFormat(translate('BlockConstructed'), 1, (c) => {
                const banned = [
                    'Intangible Virtue',
                    'Lingering Souls',
                    'AEther Vial',
                    'Ancient Den',
                    'Arcbound Ravager',
                    'Darksteel Citadel',
                    'Disciple of the Vault',
                    'Great Furnace',
                    'Seat of the Synod',
                    'Tree of Tales',
                    'Vault of Whispers',
                    'Skullclamp',
                    'Lin Sivvi, Defiant Hero',
                    'Rishadan Port',
                    "Gaea's Cradle",
                    'Memory Jar',
                    "Serra's Sanctum",
                    'Time Spiral',
                    'Tolarian Academy',
                    'Voltaic Key',
                    'Windfall',
                    'Cursed Scroll',
                    'Squandered Resources',
                    'Amulet of Quoz',
                    'Thawing Glaciers',
                    'Zuran Orb'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                const validBlocks = [
                    'Ixalan',
                    'Amonkhet',
                    'Kaladesh',
                    'Shadows over Innistrad',
                    'Battle for Zendikar',
                    'Khans of Tarkir',
                    'Theros',
                    'Return to Ravnica',
                    'Innistrad',
                    'Scars of Mirrodin',
                    'Zendikar',
                    'Shards of Alara',
                    'Lorwyn',
                    'Time Spiral',
                    'Ravnica',
                    'Kamigawa',
                    'Mirrodin',
                    'Onslaught',
                    'Odyssey',
                    'Invasion',
                    'Masques',
                    'Urza',
                    'Tempest',
                    'Mirage',
                    'Ice Age'
                ];

                return c.reprints.some((card) => validBlocks.indexOf(card.set.block.name) !== -1);
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Vintage'), 9, (c) => {
                // 08/30/2019
                const banned = [
                    'Chaos Orb',
                    'Falling Star',
                    'Lurrus of the Dream-Den',
                    'Narset, Parter of Veils',
                    'Shahrazad'
                ];

                banned.push(...this._AllFormatsBanned);

                const bannedType = ['Conspiracy'];

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                if (bannedType.indexOf(c.typeEn) !== -1) {
                    return false;
                }

                if (c.textEn.indexOf('playing for ante') !== -1) {
                    return false;
                }

                return true;
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Legacy'), 4, (c) => {
                // 07/06/2018
                const banned = [
                    'Ancestral Recall',
                    'Balance',
                    'Bazaar of Baghdad',
                    'Black Lotus',
                    'Channel',
                    'Chaos Orb',
                    'Deathrite Shaman',
                    'Demonic Consultation',
                    'Demonic Tutor',
                    'Dig Through Time',
                    'Earthcraft',
                    'Expressive Iteration',
                    'Falling Star',
                    'Fastbond',
                    'Flash',
                    'Frantic Search',
                    'Gitaxian Probe',
                    'Goblin Recruiter',
                    'Gush',
                    'Hermit Druid',
                    'Imperial Seal',
                    'Library of Alexandria',
                    'Lurrus of the Dream-Den',
                    'Mana Crypt',
                    'Mana Drain',
                    'Mana Vault',
                    'Memory Jar',
                    'Mental Misstep',
                    'Mind Twist',
                    "Mishra's Workshop",
                    'Mox Emerald',
                    'Mox Jet',
                    'Mox Pearl',
                    'Mox Ruby',
                    'Mox Sapphire',
                    'Mystical Tutor',
                    'Necropotence',
                    'Oath of Druids',
                    'Oko, Thief of Crowns',
                    'Ragavan, Nimble Pilferer',
                    "Sensei's Divining Top",
                    'Shahrazad',
                    'Skullclamp',
                    'Sol Ring',
                    'Strip Mine',
                    'Survival of the Fittest',
                    'Time Vault',
                    'Time Walk',
                    'Timetwister',
                    'Tinker',
                    'Tolarian Academy',
                    'Treasure Cruise',
                    'Vampiric Tutor',
                    'Wheel of Fortune',
                    'White Plume Adventurer',
                    'Wrenn and Six',
                    'Windfall',
                    "Yawgmoth's Bargain",
                    "Yawgmoth's Will",
                    'Zirda, the Dawnwaker'
                ];

                banned.push(...this._AllFormatsBanned);

                const bannedType = ['Conspiracy'];

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                if (bannedType.indexOf(c.typeEn) !== -1) {
                    return false;
                }

                if (c.textEn.indexOf('playing for ante') !== -1) {
                    return false;
                }

                return true;
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Commander'), 3, (c) => {
                // 01/09/2020
                const banned = [
                    'Ancestral Recall',
                    'Balance',
                    'Biorhythm',
                    'Black Lotus',
                    'Braids, Cabal Minion',
                    'Chaos Orb',
                    'Coalition Victory',
                    'Channel',
                    'Emrakul, the Aeons Torn',
                    'Erayo, Soratami Ascendant',
                    'Falling Star',
                    'Fastbond',
                    'Gifts Ungiven',
                    'Griselbrand',
                    'Hullbreacher',
                    'Iona, Shield of Emeria',
                    'Karakas',
                    'Leovold, Emissary of Trest',
                    'Library of Alexandria',
                    'Limited Resources',
                    'Mox Emerald',
                    'Mox Jet',
                    'Mox Pearl',
                    'Mox Ruby',
                    'Mox Sapphire',
                    'Nadu, Winged Wisdom',
                    'Panoptic Mirror',
                    'Paradox Engine',
                    'Primeval Titan',
                    'Prophet of Kruphix',
                    'Recurring Nightmare',
                    'Rofellos, Llanowar Emissary',
                    'Shahrazad',
                    'Sundering Titan',
                    'Sway of the Stars',
                    'Sylvan Primordial',
                    'Time Vault',
                    'Time Walk',
                    'Tinker',
                    'Tolarian Academy',
                    'Trade Secrets',
                    'Upheaval',
                    'Worldfire',
                    "Yawgmoth's Bargain"
                ];

                banned.push(...this._AllFormatsBanned);

                const bannedType = ['Conspiracy'];

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                if (bannedType.indexOf(c.typeEn) !== -1) {
                    return false;
                }

                if (c.textEn.indexOf('playing for ante') !== -1) {
                    return false;
                }

                return true;
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Brawl'), 2, (c) => {
                // 05/18/2020
                const banned = [
                    'Drannith Magistrate',
                    'Lutri, the Spellchaser',
                    'Oko, Thief of Crowns',
                    'Omnath, Locus of Creation',
                    'Runed Halo',
                    'Sorcerous Spyglass',
                    'Winota, Joiner of Forces'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                return c.reprints.some((card) => CardFormat._StandardExpansions.indexOf(card.set.sourceName) !== -1);
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Draft'), 10, (_c) => {
                return true;
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Commander Duel'), 11, (c) => {
                // 01/09/2020
                const banned = [
                    'Ancestral Recall',
                    'Ancient Tomb',
                    'Back to Basics',
                    'Black Lotus',
                    'Capture of Jingzhou',
                    'Cavern of Souls',
                    'Channel',
                    'Chrome Mox',
                    'Comet, Stellar Pup',
                    'Deadly Rollick',
                    'Deflecting Swat',
                    'Dig Through Time',
                    'Eidolon of the Great Revel',
                    'Emrakul, the Aeons Torn',
                    'Entomb',
                    'Fastbond',
                    'Field of the Dead',
                    'Fierce Guardianship',
                    'Fireblast',
                    'Food Chain',
                    'Gaea’s cradle',
                    'Genesis Storm',
                    'Golos, Tireless Pilgrim',
                    'Grim Monolith',
                    'Hermit Druid',
                    'High Tide',
                    'Humility',
                    'Imperial Seal',
                    'Karakas',
                    'Library of Alexandria',
                    "Lion's Eye Diamond",
                    'Loyal Retainers',
                    'Lutri, The Spellchaser',
                    'Mana Crypt',
                    'Mana Drain',
                    'Mana Vault',
                    "Mishra's Workshop",
                    'Mox Diamond',
                    'Mox Emerald',
                    'Mox Jet',
                    'Mox Opal',
                    'Mox Pearl',
                    'Mox Ruby',
                    'Mox Sapphire',
                    'Mystical Tutor',
                    'Natural Order',
                    'Necrotic Ooze',
                    'Oath of Druids',
                    'Polymorph',
                    'Price of Progress',
                    'Protean Hulk',
                    'Scapeshift',
                    "Sensei's Divining Top",
                    'Sol Ring',
                    'Strip Mine',
                    'Temporal Manipulation',
                    "Thassa's Oracle",
                    'The Tabernacle at Pendrell Vale',
                    'Timetwister',
                    'Time Vault',
                    'Time Walk',
                    'Time Warp',
                    'Tinker',
                    'Tolarian Academy',
                    'Treasure Cruise',
                    'Vampiric Tutor',
                    'Wasteland'
                ];

                banned.push(...this._AllFormatsBanned);

                const bannedType = ['Conspiracy'];

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                if (bannedType.indexOf(c.typeEn) !== -1) {
                    return false;
                }

                if (c.textEn.indexOf('playing for ante') !== -1) {
                    return false;
                }

                return true;
            })
        );

        CardFormat.CardFormats.push(
            new CardFormat(translate('Premodern'), 12, (c) => {
                // 9/15/2022
                const banned = [
                    'Amulet of Quoz',
                    'Balance',
                    'Brainstorm',
                    'Bronze Tablet',
                    'Channel',
                    'Demonic Consultation',
                    'Earthcraft',
                    'Entomb',
                    'Flash',
                    'Force of Will',
                    'Goblin Recruiter',
                    'Grim Monolith',
                    'Jeweled Bird',
                    'Mana Vault',
                    'Memory Jar',
                    'Mind Twist',
                    'Mind’s Desire',
                    'Mystical Tutor',
                    'Necropotence',
                    'Land Tax',
                    'Rebirth',
                    'Scroll Rack',
                    'Show and Tell',
                    'Strip Mine',
                    'Tempest Efreet',
                    'Tendril of Agony',
                    'Time Spiral',
                    'Timmerian Fiends',
                    'Tolarian Academy',
                    'Vampire Tutor',
                    'Windfall',
                    'Worldgorger Dragon',
                    'Yawgmoth’s Bargain',
                    'Yawgmoth’s Will'
                ];

                banned.push(...this._AllFormatsBanned);

                if (banned.indexOf(c.shortNameEn) !== -1) {
                    return false;
                }

                const sets = [
                    'Fourth Edition',
                    'Ice Age',
                    'Chronicles',
                    'Homelands',
                    'Alliances',
                    'Mirage',
                    'Visions',
                    'Fifht Edition',
                    'Weatherlight',
                    'Tempest',
                    'Stronghold',
                    'Exodus',
                    'Urza\'s Saga',
                    'Urza\'s Legacy',
                    'Classic Sixth Edition',
                    'Urza\'s Destiny',
                    'Mercadian Masques',
                    'Nemesis',
                    'Prophecy',
                    'Invasion',
                    'Planeshift',
                    'Seventh Edition',
                    'Apocalypse',
                    'Odyssey',
                    'Torment',
                    'Judgment',
                    'Onslaught',
                    'Legions',
                    'Scourge'
                ];

                return c.reprints.some((card) => sets.indexOf(card.set.sourceName) !== -1);
            })
        );

        CardFormat.CardFormats.sort((a, b) => a.name.localeCompare(b.name));
    }
}
