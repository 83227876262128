import * as React from 'react';
import { GlobalState, SelectionData } from '../globalState';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight, faRedoAlt, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { Collection } from '../entities/collection';
import { CardColor } from '../entities/cardColor';
import { faUnicorn } from '@fortawesome/pro-solid-svg-icons';

interface IHeaderProps {
    globalState: GlobalState;
}

export class Header extends React.Component<
    IHeaderProps,
    {
        title: string;
        text: string;
        value: string;
        highlight: boolean;
        hidden: boolean;
        isFlip: boolean;
        displaySearchButton: boolean;
        displayPrev: boolean;
        displayNext: boolean;
        displayColorButtons: boolean;
        customButtonText: string;
        orderedFlag: boolean;
        proxyFlag: boolean;
    }
> {
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;
    private onAnimationEnd?: () => void;

    constructor(props: IHeaderProps) {
        super(props);

        this.state = {
            title: '',
            text: '',
            highlight: false,
            hidden: false,
            value: '',
            isFlip: false,
            displayPrev: false,
            displayNext: false,
            displaySearchButton: false,
            customButtonText: '',
            orderedFlag: false,
            proxyFlag: false,
            displayColorButtons: false
        };
    }

    UNSAFE_componentWillMount() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        this.props.globalState.onCustomButtonRequired.add((text) => {
            this.setState({ customButtonText: text });
        });

        this.props.globalState.OnOrderedChanged.add((value) => {
            this.setState({ orderedFlag: value });
        });

        this.props.globalState.OnProxyChanged.add((value) => {
            this.setState({ proxyFlag: value });
        });

        this.props.globalState.onSearchButtonRequired.add((state) => {
            this.setState({ displaySearchButton: state });
        });

        this.props.globalState.onHeaderTextChanged.add((text) => {
            this.setState({ text: text });
        });

        this.props.globalState.onHeaderTitleChanged.add((title) => {
            this.setState({ title: title.title, isFlip: title.isFlip });
        });

        this.props.globalState.onPrevButtonRequired.add((state) => {
            this.setState({ displayPrev: state });
        });

        this.props.globalState.onNextButtonRequired.add((state) => {
            this.setState({ displayNext: state });
        });

        this.props.globalState.onHighlightRequired.add((state) => {
            this.setState({ highlight: state });
        });

        this.onSelectedMenuIndexObserver = this.props.globalState.onSelectedMenuIndexChanged.add((selection) => {
            let title = '';
            let isFlip = false;
            let displaySearchButton = false;
            let hidden = false;
            let highlight = false;
            let customButtonText = '';
            let orderedFlag = false;
            let proxyFlag = false;
            let displayColorButtons = false;
            const filter = this.props.globalState.pageCustomData
                ? this.props.globalState.pageCustomData.filter || ''
                : '';
            switch (selection.index) {
                case -8:
                    title = translate('Simulator');
                    break;
                case -7:
                    const deckToAnalyze = Collection.Decks.filter((d) => d.UniqueID === selection.uniqueString!)[0];
                    title = translate('StatisticsFor') + deckToAnalyze.Name;
                    break;
                case -6:
                    const deck = Collection.Decks.filter((d) => d.UniqueID === selection.uniqueString!)[0];
                    title = deck.Name;
                    break;
                case -5:
                    title = translate('UpdatePricesDesc');
                    break;
                case -4:
                    const card = Collection.CardsIndex[selection.cardIds![selection.uniqueID!]];
                    if (!card) {
                        return;
                    }
                    isFlip = card.tag !== '' && !!card.otherCard;
                    title = card.name;
                    customButtonText = card.rulings.length > 0 ? this.props.globalState.translate('Rulings') : '';
                    orderedFlag = card.isOrdered;
                    proxyFlag = card.isProxy;
                    break;
                case -3:
                    if (selection.title) {
                        title = selection.title;
                    } else {
                        title = translate('SearchResults');
                    }
                    break;
                case -2:
                    const expansion = Collection.GetExpansionById(selection.uniqueID!)!;
                    title = expansion.name;
                    highlight = expansion.isComplete;
                    break;
                case -1:
                    const block = Collection.GetBlockById(selection.uniqueID!);
                    highlight = block.sets.every((s) => s.isComplete);
                    title = block.name;
                    break;
                case 0:
                    title = translate('Blocks');
                    break;
                case 1:
                    title = translate('Collection_');
                    break;
                case 2:
                    title = translate('Search');
                    displaySearchButton = true;
                    break;
                case 3:
                case 15:
                case 16:
                    hidden = true;
                    break;
                case 4:
                    title = translate('Settings');
                    break;
                case 6:
                    title = translate('Statistics');
                    break;
                case 7:
                    if (selection.uniqueID) {
                        const expansionForAlbum = Collection.GetExpansionById(selection.uniqueID!)!;
                        title = translate('AlbumViewFor') + expansionForAlbum.name;
                    } else {
                        title = translate('AlbumView');
                    }
                    break;
                case 9:
                    title = translate('LifeCounters');
                    break;
                case 10:
                    title = translate('Decks_');
                    displayColorButtons = true;
                    break;
                case 11:
                    title = translate('CardScanner');
                    break;
                case 12:
                    title = translate('Checklist');
                    break;
                case 13:
                    title = translate('Lists');
                    break;
                case 14:
                    title = translate('LoreExplorer');
                    break;
            }

            if (
                this.state.title === title &&
                this.state.value === filter &&
                this.state.highlight === highlight &&
                this.state.hidden === hidden &&
                this.state.isFlip === isFlip &&
                this.state.displayColorButtons === displayColorButtons &&
                this.state.orderedFlag === orderedFlag &&
                this.state.proxyFlag === proxyFlag &&
                this.state.displaySearchButton === displaySearchButton
            ) {
                return;
            }

            this.setState({
                text: '',
                displayNext: false,
                displayPrev: false,
                customButtonText: customButtonText
            });
            const header = document.getElementById('header') as HTMLElement;

            if (this.onAnimationEnd) {
                header.classList.add('exitAnimation');
                header.classList.remove('entryAnimation');
            }

            this.onAnimationEnd = () => {
                const newState: any = {
                    title: title,
                    hidden: hidden,
                    highlight: highlight,
                    orderedFlag: orderedFlag,
                    proxyFlag: proxyFlag,
                    displayColorButtons: displayColorButtons,
                    value: filter,
                    isFlip: isFlip,
                    displaySearchButton: displaySearchButton
                };

                this.setState(newState);

                header.classList.remove('exitAnimation');
                header.classList.add('entryAnimation');
            };

            if (header.classList.contains('exitAnimation')) {
                setTimeout(() => {
                    this.onAnimationEnd && this.onAnimationEnd();
                }, 150);
            } else {
                this.onAnimationEnd();
            }
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
    }

    blurFilter(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            (evt.nativeEvent.srcElement! as HTMLInputElement).blur();
        }
    }

    selectColor(color: CardColor) {
        const index = this.props.globalState.selectedColors.indexOf(color.id);

        if (index > -1) {
            this.props.globalState.selectedColors.splice(index, 1);
        } else {
            this.props.globalState.selectedColors.push(color.id);
        }

        this.forceUpdate();
        this.props.globalState.onColorsChanged.notifyObservers();
    }

    render() {
        return (
            <div className={'header' + (this.state.hidden ? ' notVisible' : '')} id="header">
                {this.state.isFlip && (
                    <div
                        className="header-flip button"
                        onClick={() => this.props.globalState.onFlipRequired.notifyObservers()}
                    >
                        <FontAwesomeIcon icon={faRedoAlt} />
                    </div>
                )}
                <div className={'header-title' + (this.state.highlight ? ' highlight' : '')}>
                    {this.state.orderedFlag && <FontAwesomeIcon icon={faShoppingBasket} className="header-ordered" />}
                    {this.state.proxyFlag && <FontAwesomeIcon icon={faUnicorn} className="header-ordered" />}
                    {this.state.title}
                </div>
                {<div className={'header-text' + (this.state.highlight ? ' highlight' : '')}>{this.state.text}</div>}
                {(this.state.displayNext ||
                    this.state.displayPrev ||
                    this.state.displayColorButtons ||
                    this.state.displaySearchButton ||
                    this.state.customButtonText) && (
                        <div className="header-commands">
                            {this.state.displayColorButtons && (
                                <div className="header-colors">
                                    {CardColor.SortedColors.filter((color) => color.id > 0 && color.id < 7).map((color) => {
                                        return (
                                            <div
                                                key={color.id}
                                                className={
                                                    'header-color button' +
                                                    (this.props.globalState.selectedColors.indexOf(color.id) !== -1
                                                        ? ' color-selected'
                                                        : '')
                                                }
                                                onClick={() => this.selectColor(color)}
                                            >
                                                <img src={'/images/symbols/' + color.key + '.png'} />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {this.state.displaySearchButton && (
                                <div className="validate button">
                                    <Button
                                        globalState={this.props.globalState}
                                        onClicked={() => this.props.globalState.onSearch.notifyObservers()}
                                        className="search-button"
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </div>
                            )}
                            {this.state.displayPrev && (
                                <div
                                    className="prev button"
                                    onClick={() => this.props.globalState.onPrev.notifyObservers()}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </div>
                            )}
                            {this.state.customButtonText !== '' && (
                                <Button
                                    globalState={this.props.globalState}
                                    className="header-custom-button"
                                    onClicked={() => this.props.globalState.onCustomButtonClicked.notifyObservers()}
                                >
                                    {this.state.customButtonText}
                                </Button>
                            )}
                            {this.state.displayNext && (
                                <div
                                    className="next button"
                                    onClick={() => this.props.globalState.onNext.notifyObservers()}
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                            )}
                        </div>
                    )}
            </div>
        );
    }
}
