import * as React from 'react';
import { MenuItem } from './menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalState, SelectionData, SearchData } from '../globalState';
import { Observer } from '../tools/observable';
import { Nullable } from '../tools/nullable';
import {
    faCog,
    faBars,
    faSearch,
    faQuestionCircle,
    faRandom,
    faChartBar,
    faThLarge,
    faDiceD20,
    faBox,
    IconDefinition,
    faTasks,
    faList
} from '@fortawesome/free-solid-svg-icons';
import { faTreasureChest, faScanner, faStar, faSearchPlus, faInfo } from '@fortawesome/pro-solid-svg-icons';
import { faPatreon } from '@fortawesome/fontawesome-free-brands';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { PatreonTools, PatreonLevel } from '../tools/patreonTools';
import { MobileTools } from '../tools/mobileTools';
import { LabelledTexbox } from './labelledTextbox';
import { Collection } from '../entities/collection';
import { App } from '../app';

interface ISidebarProps {
    globalState: GlobalState;
    isOpen: boolean;
    onHamburgerClicked: () => void;
    isPatreon: boolean;
}

export class Sidebar extends React.Component<ISidebarProps, { query: string }> {
    private _openBySearch = false;
    private onSelectedMenuIndexObserver: Nullable<Observer<SelectionData>>;
    private onSearchShortcutObserver: Nullable<Observer<void>>;

    constructor(props: ISidebarProps) {
        super(props);

        this.state = { query: '' };
    }

    hamburgerClick() {
        this.props.onHamburgerClicked();
        setTimeout(() => {
            this.props.globalState.onHamburgerClicked.notifyObservers();
        }, 150);
    }

    UNSAFE_componentWillMount() {
        this.onSelectedMenuIndexObserver = this.props.globalState.onSelectedMenuIndexChanged.add((_selection) => {
            if (this.props.isOpen && App.NeedSidebarOverlay()) {
                this.hamburgerClick();
            }
        });

        this.onSearchShortcutObserver = this.props.globalState.onSearchShortcut.add(() => {
            if (!this.props.isOpen) {
                this.hamburgerClick();
            }

            setTimeout(() => {
                if (MobileTools.IsMobile) {
                    return;
                }
                const input = document.querySelector('.search-box-text')! as HTMLInputElement;
                input.focus();
                if (input.value) {
                    input.selectionStart = 0;
                    input.selectionEnd = input.value.length;
                }
            }, 250);
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSelectedMenuIndexChanged.remove(this.onSelectedMenuIndexObserver);
        this.props.globalState.onSearchShortcut.remove(this.onSearchShortcutObserver);
    }

    doSearch() {
        const searchBox = document.querySelector('.search-box-text')! as HTMLInputElement;

        if (searchBox && searchBox.value) {
            const searchData = new SearchData();

            searchData.name = searchBox.value;

            this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -3, searchData: searchData });
        }
    }

    blurTextField(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            this.doSearch();
        }
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        let patreonClassname = '';

        switch (PatreonTools.PledgeLevel) {
            case PatreonLevel.Bronze:
                patreonClassname = ' bronze';
                break;
            case PatreonLevel.Silver:
                patreonClassname = ' silver';
                break;
            case PatreonLevel.Gold:
                patreonClassname = ' gold';
                break;
        }

        if (this._openBySearch) {
            setTimeout(() => {
                if (MobileTools.IsMobile) {
                    return;
                }
                (document.querySelector('.search-box-text')! as HTMLInputElement).focus();
            }, 250);
        }

        const suggestionForNames = Collection.Cards.map((c) => c.nameEn);

        if (Collection.UseFrenchForTexts) {
            for (const cardName of Collection.Cards.map((c) => c.nameFr)) {
                if (!cardName) {
                    continue;
                }
                suggestionForNames.push(cardName);
            }
        }

        this._openBySearch = false;
        return (
            <div className={'sidebar ' + (this.props.isOpen ? 'opened' : 'closed')}>
                <div className="titleBar">
                    <div className="hamburgerMenu button" onClick={() => this.hamburgerClick()}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div className="title">UrzaGatherer</div>
                    {this.props.isPatreon && (
                        <div className={'patreon' + patreonClassname}>
                            <FontAwesomeIcon icon={faStar as Icon} />
                        </div>
                    )}
                </div>
                <div className="menu-items">
                    {this.props.isOpen && (
                        <div className="search-box">
                            <LabelledTexbox
                                label=""
                                globalState={this.props.globalState}
                                onlyInput={true}
                                className="search-box-text"
                                onKeyPress={(evt) => this.blurTextField(evt)}
                                onChange={(value) => {
                                    this.setState({ query: value });
                                }}
                                onSuggestionPicked={() => {
                                    setTimeout(() => {
                                        this.doSearch();
                                    });
                                }}
                                suggestions={suggestionForNames}
                                value={this.state.query}
                                placeholder={translate('search...')}
                            />
                            <div className="search-box-icon" onClick={() => this.doSearch()}>
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                        </div>
                    )}
                    {!this.props.isOpen && (
                        <div>
                            <MenuItem
                                onClick={() => {
                                    this.hamburgerClick();
                                    this._openBySearch = true;
                                }}
                                globalState={this.props.globalState}
                            >
                                <FontAwesomeIcon icon={faSearch as Icon} />
                            </MenuItem>
                            <div className="menu-separator" />
                        </div>
                    )}
                    {
                        !GlobalState.LoadBoolSettings('HideDealPage', false) &&
                        <MenuItem label={translate('Deal')} index={16} globalState={this.props.globalState}>
                            <FontAwesomeIcon icon={faStar as Icon} className='glowAnimation' />
                        </MenuItem>
                    }
                    <MenuItem label={translate('Search')} index={2} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faSearchPlus as Icon} />
                    </MenuItem>
                    <MenuItem label={translate('Collection_')} index={1} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faTreasureChest as IconDefinition} />
                    </MenuItem>
                    <MenuItem label={translate('Blocks')} index={0} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faThLarge} />
                    </MenuItem>
                    <MenuItem label={translate('Checklist')} index={12} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faTasks} />
                    </MenuItem>
                    <MenuItem label={translate('Decks_')} index={10} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faBox} />
                    </MenuItem>
                    <MenuItem label={translate('Lists')} index={13} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faList} />
                    </MenuItem>
                    <MenuItem
                        label={translate('Random')}
                        additionalSelectionCheck={(selection) => {
                            if (selection.cardIds && selection.cardIds.length > 1) {
                                return false;
                            }

                            return true;
                        }}
                        index={-4}
                        globalState={this.props.globalState}
                    >
                        <FontAwesomeIcon icon={faRandom} />
                    </MenuItem>
                    <MenuItem label={translate('Statistics')} index={6} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faChartBar} />
                    </MenuItem>
                    <MenuItem label={translate('LifeCounters')} index={9} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faDiceD20} />
                    </MenuItem>
                    <MenuItem label={translate('CardScanner')} index={11} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faScanner as Icon} />
                    </MenuItem>
                    <div className="menu-separator" />
                    {!this.props.isPatreon && (
                        <MenuItem
                            label={'Patreon'}
                            index={99}
                            url="https://www.patreon.com/urzagatherer"
                            globalState={this.props.globalState}
                        >
                            <FontAwesomeIcon icon={faPatreon as Icon} />
                        </MenuItem>
                    )}
                    <MenuItem label={translate('Help')} index={15} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </MenuItem>
                    <MenuItem label={translate('About')} index={3} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faInfo} />
                    </MenuItem>
                </div>
                <div className="menu-footer">
                    <MenuItem label={translate('Settings')} large={true} index={4} globalState={this.props.globalState}>
                        <FontAwesomeIcon icon={faCog} />
                    </MenuItem>
                </div>
            </div>
        );
    }
}
