/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { GlobalState } from '../globalState';

require('../scss/star.scss');

interface IStarPageProps {
    globalState: GlobalState;
}

export class StarPageFR extends React.Component<IStarPageProps, { showConsole: boolean, showDebugInfo: boolean }> {
    constructor(props: IStarPageProps) {
        super(props);
    }

    componentWillUnmount() {
        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidMount() {
        // nothing to do
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className="page">
                <div className="star-page">
                    <h1>
                        Le Deal : Accédez presque gratuitement au Patreon d'Urzagatherer !
                    </h1>
                    <h3>Le Contexte :</h3>
                    <div className="star-text">
                        En plus de travailler sur Urzagatherer, je suis également écrivain et illustrateur. J'ai publié deux nouvelles illustrés :
                        <div className='star-table'>
                            <div className='text'><a href="https://www.amazon.fr/dp/B0DSFRMHBY">La Légion Zodiaque :</a></div>
                            <div className='image'><a href="https://www.amazon.fr/dp/B0DSFRMHBY"><img className="ads" src={'/images/' + translate('ZodiacLegionAdsImg')} /></a></div>
                            <div className='text'><a href="https://www.amazon.fr/dp/B0DSJ81NY6">Compendium de Magie:</a></div>
                            <div className='image'><a href="https://www.amazon.fr/dp/B0DSJ81NY6"><img className="ads" src={'/images/' + translate('MagicCompendiumAdsImg')} /></a></div>
                        </div>
                        Les avis sont essentiels pour tout auteur auto-publié, et du coup nous pourrions conclure un marché ! :)
                    </div>
                    <p>
                        <h3>Voici ce que je propose :</h3>
                        <p>
                            Si vous m'envoyez un email à <a href="mailto:david@catuhe.com">david@catuhe.com</a> avec une capture d'écran d'un avis d'achat vérifié pour l'un de mes livres,<br></br>
                            <b>ET</b><br></br>
                            si vous êtes d'accord pour recevoir un email par an de ma part à propos de mon nouveau livre,<br></br>
                            <b>ALORS</b><br></br>
                            je vous remercierai avec 2 ans d'<b><u>accès complet à Patreon</u></b> par livre évalué.<br></br>
                            <br></br>
                            Donc, si vous achetez un livre (dans n'importe quel format—le moins cher étant Kindle à 3,99 euros), vous recevrez un accès Patreon d'une valeur de <b><u>20 euros</u></b>.<br></br>
                            Si vous achetez les deux livres (et bien sûr, laissez un avis honnête), vous obtiendrez un accès Patreon d'une valeur de <b><u>40 euros</u></b>.
                            <br></br>
                            <br></br>
                            Une offre plutôt sympa, non ?
                        </p>
                    </p>
                </div>
            </div>
        );
    }
}
